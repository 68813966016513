import { capitalize, cn } from "../../util/helpers";
import { BodyText } from "../typography";
import { useId } from "react";
import { useBrandingStore } from "../../store";

type SwitchProps = {
	label?: string | null;
	value: boolean;
	setValue: (value: boolean) => void;
	disabled?: boolean;
	alert?: string;
	info?: string;
	className?: string;
};

const Switch = ({
	value,
	setValue,
	disabled,
	label,
	alert,
	info,
	className,
}: SwitchProps) => {
	const id = useId();
	const { branding } = useBrandingStore();

	return (
		<label
			htmlFor={id}
			className={cn(
				"flex cursor-pointer flex-row items-start gap-3",
				branding !== "hoya" && "flex-row-reverse justify-between",
				className,
			)}
			onClick={(e) => {
				if (disabled) {
					return;
				}
				setValue(!value);
				e.preventDefault();
			}}
		>
			<div className="relative mt-1">
				<input
					id={id}
					type="checkbox"
					className="sr-only"
					checked={value}
					disabled={disabled}
					onChange={() => setValue(!value)}
				/>
				{branding !== "hoya" ? (
					<>
						<div
							className={cn(
								value && "bg-system-success-100",
								!value && "bg-quaternary-80",
								disabled && "bg-quaternary-100",
								"h-6 w-12 rounded-full",
							)}
						></div>
						<div
							className={cn(
								"absolute left-1/2 top-[3px] size-[18px] -translate-x-[calc(50%+12px)] rounded-full bg-pure-white transition",
								value && "translate-x-[calc(50%-6px)]",
								disabled && "bg-pure-white",
							)}
						></div>
					</>
				) : (
					<>
						<div
							className={cn(
								value && !disabled && "bg-primary-60",
								!value && !disabled && "bg-secondary-100",
								value && disabled && "bg-secondary-40",
								!value && disabled && "bg-secondary-40",
							)}
						></div>
						<div
							className={cn(
								value &&
									!disabled &&
									"translate-x-full bg-primary-100",
								!value &&
									!disabled &&
									"translate-x-0 bg-secondary-20",
								value &&
									disabled &&
									"translate-x-full bg-secondary-40",
								!value &&
									disabled &&
									"translate-x-0 bg-secondary-40",
							)}
						></div>
					</>
				)}
			</div>
			{label && (
				<div
					className={cn(
						"flex flex-col gap-1",
						branding !== "hoya" && "gap-2.5",
					)}
				>
					<BodyText
						type="normal"
						className="select-none text-primary-dark-100"
					>
						{label}
					</BodyText>
					{alert && (
						<BodyText
							type="mini"
							className="select-none text-system-danger-100"
						>
							{capitalize(alert)}
						</BodyText>
					)}
					{info && (
						<BodyText
							type="mini"
							className="select-none text-primary-dark-60"
						>
							{capitalize(info)}
						</BodyText>
					)}
				</div>
			)}
		</label>
	);
};

export default Switch;
