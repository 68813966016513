import { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import { CustomSelect, TextField } from "../../components/input";
import { BodyText, HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import {
	getHoyasettings,
	getSettings,
	home,
	setHoyasettings,
	setShopName,
} from "../../util/api/api-store";
import languageIcons from "../../util/LanguageIcons";
import { cn, CountryCodes } from "../../util/helpers";
import { format } from "date-fns";
import {
	RadioBtnEmpty,
	RadioBtnFilled,
	Tick,
	Warning,
} from "../../components/icons";
import { useOAuth2 } from "@moon-packages/react-use-oauth2";
import { ModalBase } from "../../components/modals";

const Profile = () => {
	const { t, i18n } = useTranslation();
	const [selectedLanguageCode, setSelectedLanguageCode] =
		useState<string>("");
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const openSnack = useSnackStore((state) => state.open);
	const { branding } = useBrandingStore();
	const [modalCIAMOpen, setModalCIAMOpen] = useState<boolean>(false);
	const [CIAMLoginState, setCIAMLoginState] = useState<
		"Success" | "Failed" | "Default"
	>("Default");

	const languageOptions = languageIcons.map((lang) => ({
		value: lang.code,
		label: lang.name,
	}));

	const [formData, setFormData] = useState({
		ciam_credentials: {
			login: "",
			password: "",
		},
		shop_name: "",
		country_code: "",
		customer_email: "",
		customer_login: "",
		customer_number: "",
		customer_password: "",
		ilog_access: 0,
		oauth2_credentials: {
			access_token: "",
			login_time: "",
			refresh_token: "",
		},
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setSelectedLanguageCode(lng);
	};

	useEffect(() => {
		setSelectedLanguageCode(getCurrentLng());

		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			getHoyasettings.setRequest(null);
			getHoyasettings.fetchData().then((hoyaResp) => {
				setFormData({
					...formData,
					ciam_credentials: hoyaResp.ciam_credentials,
					shop_name: resp.shop_name || "",
					country_code: hoyaResp.country_code,
					customer_email: hoyaResp.customer_email,
					customer_login: hoyaResp.customer_login,
					customer_number: hoyaResp.customer_number,
					customer_password: hoyaResp.customer_password,
					ilog_access: hoyaResp.ilog_access,
					oauth2_credentials: hoyaResp.oauth2_credentials,
				});
			});
		});
	}, []);

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const tHoyasettingsEmail = t("hoyasettings.email");
	const tHoyasettingsTitle = t("hoyasettings.title");

	const onSubmit = () => {
		setIsSubmited(true);
		setShopName.setRequest({
			shop_name: formData.shop_name,
		});
		setShopName.fetchData().then(() => {
			const data = {
				country_code: formData.country_code,
				customer_email: formData.customer_email,
				customer_login: formData.customer_login,
				customer_number: formData.customer_number,
				customer_password: formData.customer_password,
				ciam_credentials: formData.ciam_credentials,
				manufacturer_code: "hoy",
				transmit_face_form_angle: true,
				ilog_access: formData.ilog_access,
				oauth2_credentials: formData.oauth2_credentials,
			};

			setHoyasettings.setRequest(data);
			setHoyasettings
				.fetchData()
				.then(() => {
					getHoyasettings.invalidate();
					getHoyasettings.fetchData();
					openSnack(t("profile.savedSuccessfully"), "ok");
					setIsSubmited(false);
				})
				.catch((error) => {
					console.error(error);
					setIsSubmited(false);
				});
		});
		getSettings.invalidate();
		getSettings.fetchData();
	};

	const handleRedirectModalClose = () => {
		setModalCIAMOpen(false);
	};

	const { getAuth } = useOAuth2({
		authorizeUrl: `${
			import.meta.env.VITE_HOYA_OAUTH2_URL
		}/services/oauth2/authorize`,
		clientId: import.meta.env.VITE_HOYA_CLIENT_ID,
		redirectUri: `${import.meta.env.VITE_HOYA_REDIRECT_URI}/#/hoya-token`,
		responseType: "code",
		scope: "api order order_session order_shipping_read",
		exchangeCodeForTokenQueryFn: async (callbackParameters: {
			code: string;
		}) => {
			console.log(callbackParameters);

			const jsonObject = {
				code: callbackParameters.code,
				grant_type: "authorization_code",
				redirect_uri: `${document.location.origin}/#/hoya-token`,
				client_id: import.meta.env.VITE_HOYA_CLIENT_ID,
				client_secret: import.meta.env.VITE_HOYA_CLIENT_SECRET,
			};

			const formBody = [];

			for (const key in jsonObject) {
				formBody.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(
						jsonObject[key as keyof typeof jsonObject],
					)}`,
				);
			}
			console.log(formBody);

			const response = await fetch(
				`${import.meta.env.VITE_HOYA_OAUTH2_URL}/services/oauth2/token`,
				{
					method: "POST",
					body: formBody.join("&"),
					headers: {
						"Content-Type":
							"application/x-www-form-urlencoded;charset=UTF-8",
					},
				},
			);

			if (!response.ok)
				throw new Error("exchangeCodeForTokenQueryFn fail at example");
			const tokenData = await response.json();
			console.log(tokenData);
			return tokenData;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSuccess: (payload: any) => {
			console.log("Success", payload);

			const data = {
				ciam_credentials: formData.ciam_credentials,
				country_code: formData.country_code,
				customer_email: formData.customer_email,
				customer_login: formData.customer_login,
				customer_number: formData.customer_number,
				customer_password: formData.customer_password,
				ilog_access: 2,
				oauth2_credentials: {
					access_token: payload.access_token,
					login_time: payload.issued_at,
					refresh_token: payload.refresh_token,
				},
				manufacturer_code: "hoy",
				transmit_face_form_angle: true,
			};

			setHoyasettings.setRequest(data);
			setHoyasettings
				.fetchData()
				.then(() => {
					getHoyasettings.invalidate();
					getHoyasettings.fetchData().then((hoyaResp) => {
						setFormData({
							...formData,
							ilog_access: hoyaResp.ilog_access,
							oauth2_credentials: hoyaResp.oauth2_credentials,
						});
					});

					setCIAMLoginState("Success");
					setTimeout(() => {
						setModalCIAMOpen(false);
						setFormData({
							...formData,
							ilog_access: 2,
						});
						setCIAMLoginState("Default");
					}, 2000);
				})
				.catch((error) => {
					console.error(error);
					setCIAMLoginState("Failed");
				});
		},
		onError: (error_: string) => {
			console.error("Error", error_);
			setCIAMLoginState("Failed");
		},
	});

	return (
		<>
			<div>
				<HeadingText
					h={4}
					className={cn(branding !== "hoya" && "mt-2 !text-[26px]")}
				>
					{t("profile.pageTitle")}
				</HeadingText>
				<SettingsFragment
					title={t("profile.basicSettings") || undefined}
					className={cn(branding !== "hoya" && "pb-10 pt-7")}
				>
					<div
						className={cn(
							"flex flex-col",
							branding !== "hoya" ? "gap-6" : "gap-8",
						)}
					>
						<TextField
							type="default"
							htmlFor="shop_name"
							label={t("profile.shopName")}
							info={
								t("profile.shopNameDescription", {
									length: formData.shop_name.length,
								}) || undefined
							}
							fullWidth
							value={formData.shop_name}
							setValue={(v) => {
								setFormData({
									...formData,
									shop_name: v.slice(0, 20),
								});
							}}
						/>
						<CustomSelect
							label={t("profile.language")}
							value={selectedLanguageCode}
							setValue={(v) => {
								changeLanguage(v);
							}}
							options={languageOptions}
							fullWidth
						/>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("profile.emailSettings") || undefined}
					borderTop
					borderBottom
					className={cn(branding !== "hoya" && "mb-6 py-9")}
				>
					<div className={cn("flex flex-col gap-8")}>
						<TextField
							type="default"
							htmlFor="customer_email"
							label={t("profile.email")}
							info={tHoyasettingsEmail}
							fieldOptions={{ type: "email" }}
							fullWidth
							value={formData.customer_email}
							setValue={(v) =>
								setFormData({
									...formData,
									customer_email: v,
								})
							}
						/>
					</div>
				</SettingsFragment>
				{branding === "hoya" && (
					<SettingsFragment title={tHoyasettingsTitle}>
						<BodyText type="normal">
							{t("hoyasettings.text")}
						</BodyText>
						<div className={cn("flex flex-col gap-3 pt-8")}>
							<div
								className={cn(
									"grid h-12 grid-cols-[auto_1fr] border",
									formData.ilog_access === 0
										? "border-primary-100"
										: "cursor-pointer border-secondary-100",
								)}
								onClick={() =>
									setFormData({
										...formData,
										ilog_access: 0,
									})
								}
							>
								<div
									className={cn(
										"flex h-12 w-12 items-center justify-center text-primary-100",
									)}
								>
									{formData.ilog_access === 0 ? (
										<RadioBtnFilled />
									) : (
										<RadioBtnEmpty />
									)}
								</div>
								<BodyText
									type={
										formData.ilog_access === 0
											? "bold16"
											: "normal"
									}
									className="h-fit self-center"
								>
									{t("hoyasettings.none")}
								</BodyText>
							</div>
							<BodyText
								type="normal"
								className={cn(
									"h-fit w-fit self-center text-primary-dark-60",
								)}
							>
								{t("hoyasettings.or")}
							</BodyText>
							<div
								className={cn(
									"grid h-12 grid-cols-[auto_1fr] border",
									formData.ilog_access === 2
										? "border-primary-100"
										: "cursor-pointer border-secondary-100",
								)}
								onClick={() => setModalCIAMOpen(true)}
							>
								<div
									className={cn(
										"flex h-12 w-12 items-center justify-center text-primary-100",
									)}
								>
									{formData.ilog_access === 2 ? (
										<RadioBtnFilled />
									) : (
										<RadioBtnEmpty />
									)}
								</div>
								<div className="flex items-center justify-between pr-6">
									<BodyText
										type={
											formData.ilog_access === 2
												? "bold16"
												: "normal"
										}
										className="h-fit self-center"
									>
										{t("hoyasettings.ciam")}
									</BodyText>
									{formData.oauth2_credentials.login_time && (
										<BodyText type="small">{`Signed in (${format(
											parseInt(
												formData.oauth2_credentials
													.login_time,
											),
											"yyyy-MM-dd",
										)})`}</BodyText>
									)}
								</div>
							</div>
							<BodyText
								type="normal"
								className={cn(
									"h-fit w-fit self-center text-primary-dark-60",
								)}
							>
								{t("hoyasettings.or")}
							</BodyText>
							<div
								className={cn(
									"grid grid-cols-[auto_1fr] border",
									formData.ilog_access === 1
										? "h-fit grid-rows-[auto_auto] border-primary-100"
										: "h-12 cursor-pointer grid-rows-1 border-secondary-100",
								)}
								onClick={() =>
									setFormData({
										...formData,
										ilog_access: 1,
									})
								}
							>
								<div
									className={cn(
										"flex h-12 w-12 items-center justify-center text-primary-100",
									)}
								>
									{formData.ilog_access === 1 ? (
										<RadioBtnFilled />
									) : (
										<RadioBtnEmpty />
									)}
								</div>
								<BodyText
									type={
										formData.ilog_access === 1
											? "bold16"
											: "normal"
									}
									className={cn("h-fit self-center")}
								>
									{t("hoyasettings.ilog/ilink")}
								</BodyText>
								{formData.ilog_access === 1 && (
									<div
										className={cn(
											"col-start-2 row-start-2 flex flex-col gap-6 pb-12 pr-12 pt-4",
										)}
									>
										<TextField
											type="default"
											htmlFor="ilink-customer_number"
											label={t(
												"hoyasettings.customerNumber",
											)}
											fullWidth
											value={formData.customer_number}
											setValue={(v) =>
												setFormData({
													...formData,
													customer_number: v,
												})
											}
										/>
										<TextField
											type="default"
											htmlFor="ilink-customer_login"
											label={t("profile.username")}
											fullWidth
											value={formData.customer_login}
											setValue={(v) =>
												setFormData({
													...formData,
													customer_login: v,
												})
											}
										/>
										<TextField
											type="default"
											htmlFor="ilink-customer_password"
											label={t(
												"hoyasettings.customerPassword",
											)}
											fieldOptions={{ type: "password" }}
											fullWidth
											value={formData.customer_password}
											setValue={(v) =>
												setFormData({
													...formData,
													customer_password: v,
												})
											}
										/>
										<CustomSelect
											label={t("hoyasettings.country")}
											value={formData.country_code}
											setValue={(v) => {
												setFormData({
													...formData,
													country_code: v,
												});
											}}
											options={CountryCodes}
											fullWidth
										/>
									</div>
								)}
							</div>
						</div>
					</SettingsFragment>
				)}
				<div
					ref={buttonRef}
					className={cn(
						"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={cn(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
						)}
					>
						<SolidButton
							color="primary"
							onClick={onSubmit}
							disabled={isSubmited}
						>
							{t("profile.save")}
						</SolidButton>
					</div>
				</div>
			</div>
			<ModalBase
				title={t("hoyasettings.modalTitle")}
				open={modalCIAMOpen}
				close={handleRedirectModalClose}
			>
				{CIAMLoginState === "Default" && (
					<div className={cn("flex flex-col gap-6 p-6")}>
						<p>{t("hoyasettings.needRedirect")}</p>
						<p className="text-right">
							<SolidButton color="primary" onClick={getAuth}>
								{t("hoyasettings.customerLogin")}
							</SolidButton>
						</p>
					</div>
				)}
				{CIAMLoginState === "Success" && (
					<div
						className={cn(
							"flex h-[19rem] flex-col items-center justify-center gap-6 px-6 py-12",
						)}
					>
						<div>
							<Tick />
						</div>
						<div className={cn("flex flex-col gap-2")}>
							<BodyText type="bold16" className="text-center">
								{t("hoyasettings.signedSuccess")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								{t("hoyasettings.windowClose")}
							</BodyText>
						</div>
					</div>
				)}
				{CIAMLoginState === "Failed" && (
					<div
						className={cn(
							"flex h-[19rem] flex-col items-center justify-center gap-6 px-6 py-12",
						)}
					>
						<div>
							<Warning />
						</div>
						<div className={cn("flex flex-col gap-2")}>
							<BodyText type="bold16" className="text-center">
								{t("hoyasettings.signedFail")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								{t("hoyasettings.tryAgain")}
							</BodyText>
							<p className="mt-6">
								<SolidButton color="primary" onClick={getAuth}>
									{t("hoyasettings.customerLogin")}
								</SolidButton>
							</p>
						</div>
					</div>
				)}
			</ModalBase>
		</>
	);
};

export default Profile;
