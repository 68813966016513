/* eslint-disable no-constant-condition */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { about, getSettings } from "../../util/api/api-store";
import { bytesToSize, cn } from "../../util/helpers";
import { TextButton } from "../buttons";
import { Add } from "../icons";
import BrandLogo from "../logo/BrandLogo";
import { BodyText, HeadingText } from "../typography";
import TextBody from "../typography/TextBody";
import { useBrandingStore } from "../../store";

type ModalSystemInfoProps = {
	defaultOpen?: boolean;
};

const ModalSystemInfo = ({ defaultOpen }: ModalSystemInfoProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [open, setOpen] = useState(defaultOpen ?? false);
	const systemInfoResponse = about.useGetResult();

	const systemInfo = systemInfoResponse.unwrapOrDefault({
		master_system: "",
		mainboard_serial_nr: "",
		public_version: "",
		space_info: {
			available: 0,
			capacity: 0,
			free: 0,
		},
		system_nr: 0,
		user_instructions_url: "", // Could be a problem
		versions: {
			firm_ware: "",
			frame_tracer: "",
			hard_ware: "",
			inet_tools_master: "",
			inet_tools_tegra: "",
			master: "",
			nxweb: "",
			server: "",
			user_interface: "",
			web_handler: "",
		},
	});

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	useEffect(() => {
		about.setRequest(null);
		about.fetchData();

		getSettings.setRequest(null);
		getSettings.fetchData();
	}, []);

	return (
		<div
			className={cn(
				branding !== "hoya" ? "flex flex-col px-12 py-10" : "p-6",
			)}
		>
			{branding !== "hoya" ? (
				<div className={cn("flex flex-col gap-8")}>
					<div className={cn("grid grid-cols-2 gap-7")}>
						<div
							className={cn(
								"grid place-items-center gap-2 rounded-[20px] bg-quaternary-100 p-5",
							)}
						>
							<BodyText type="bold16" className="text-center">
								{t("modalSystemInfo.manufacturedBy")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								visuSolution GmbH
							</BodyText>
						</div>
						<div
							className={cn(
								"grid place-items-center gap-2 rounded-[20px] bg-quaternary-100 p-5",
							)}
						>
							<BodyText type="bold16" className="text-center">
								{t("modalSystemInfo.developedBy")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								Ollendorf Measurement Systems GmbH
							</BodyText>
						</div>
					</div>
					<hr className="border-t-quaternary-100" />
					<div
						className={cn(
							"grid w-full grid-cols-2 place-items-start gap-5 rounded-full bg-primary-100",
						)}
					>
						<BodyText
							type="bold16"
							className={cn("ml-5 py-2 text-pure-white")}
						>
							{t("modalSystemInfo.masterNr", {
								nr: systemInfo.system_nr,
							})}
						</BodyText>
						<BodyText
							type="bold16"
							className="py-2 text-pure-white"
						>
							{t("modalSystemInfo.version", {
								version: systemInfo.public_version,
							})}
						</BodyText>
					</div>
				</div>
			) : (
				<div className={cn("grid grid-cols-2 gap-x-6")}>
					<div
						className={cn(
							"flex h-[72px] flex-row items-center justify-center bg-secondary-40 p-2.5",
						)}
					>
						<HeadingText h={4}>
							{t("modalSystemInfo.masterNr", {
								nr: systemInfo.system_nr,
							})}
						</HeadingText>
					</div>
					<div
						className={cn(
							"flex h-[72px] flex-row items-center justify-center bg-secondary-40 p-2.5",
						)}
					>
						<HeadingText h={4}>
							{t("modalSystemInfo.version", {
								version: systemInfo.public_version,
							})}
						</HeadingText>
					</div>
				</div>
			)}
			{!open &&
				(branding !== "hoya" ? (
					<TextButton
						icon={Add}
						color="primary"
						iconPosition="suffix"
						iconClasses={cn(
							"size-[30px] !rounded-[10px] border border-quaternary-80 p-[2px]",
						)}
						onClick={() => setOpen(true)}
						className="mt-4 self-end !p-0"
					>
						{t("modalSystemInfo.showMore")}
					</TextButton>
				) : (
					<TextButton
						icon={Add}
						color="primary"
						onClick={() => setOpen(true)}
						className="my-3"
					>
						{t("modalSystemInfo.showMore")}
					</TextButton>
				))}
			{open && (
				<div
					className={cn(
						"my-3 grid grid-cols-2 gap-x-6 gap-y-3",
						branding !== "hoya" && "mt-4 gap-y-2 px-5",
					)}
				>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.systemNr")}
					</TextBody>
					<TextBody type="bold16">{systemInfo.system_nr}</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.firmware")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.firm_ware}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.server")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.server}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.frameTracer")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.frame_tracer}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.ui")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.user_interface}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.webServer")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.nxweb}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.webHandler")}
					</TextBody>
					<TextBody type="bold16">
						{systemInfo.versions.web_handler}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.spaceAvailable")}
					</TextBody>
					<TextBody type="bold16">
						{bytesToSize(systemInfo.space_info.available)}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.spaceCapacity")}
					</TextBody>
					<TextBody type="bold16">
						{bytesToSize(systemInfo.space_info.capacity)}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.spaceFree")}
					</TextBody>
					<TextBody type="bold16">
						{bytesToSize(systemInfo.space_info.free)}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.branding")}
					</TextBody>
					<TextBody type="bold16" className="capitalize">
						{settings.branding}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.b2bInterface")}
					</TextBody>
					<TextBody type="bold16">
						{settings.modules.b2b_optic
							? t("modalSystemInfo.activated")
							: t("modalSystemInfo.notActivated")}
					</TextBody>
					<TextBody
						type="normal"
						className={cn(
							branding !== "hoya" &&
								"relative pl-5 before:absolute before:left-1.5 before:top-1/2 before:h-1 before:w-1 before:-translate-y-1/2 before:rounded-full before:bg-pure-black",
						)}
					>
						{t("modalSystemInfo.adminMode")}
					</TextBody>
					<TextBody type="bold16">
						<Link to="/admin">
							{settings.admin_mode
								? t("modalSystemInfo.activated")
								: t("modalSystemInfo.notActivated")}
						</Link>
					</TextBody>
				</div>
			)}
			{branding === "hoya" && (
				<div className={cn("my-6 flex flex-row justify-between")}>
					<div className={cn("flex flex-col justify-start gap-3")}>
						<BodyText type="mini" className="text-primary-dark-60">
							{t("modalSystemInfo.developedBy")}
						</BodyText>
						<BrandLogo override="ollendorf" size="40px" />
					</div>

					<div className={cn("flex flex-col justify-end gap-3")}>
						<BodyText type="mini" className="text-primary-dark-60">
							{t("modalSystemInfo.manufacturedBy")}
						</BodyText>
						<BrandLogo override="vs" size="40px" />
					</div>
				</div>
			)}
		</div>
	);
};

export default ModalSystemInfo;
