import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	ciam_credentials: {
		login: string;
		password: string;
	};
	country_code: string;
	customer_email: string;
	customer_login: string;
	customer_number: string;
	customer_password: string; // WHAT??
	manufacturer_code: string;
	transmit_face_form_angle: boolean;
	ilog_access: number;
	oauth2_credentials: {
		access_token: string;
		login_time: string;
		refresh_token: string;
	};
}

export type IResponseError = null;

const responseDataShape = J.object({
	ciam_credentials: J.object({
		login: J.string().allow(""),
		password: J.string().allow(""),
	}).unknown(),
	country_code: J.string().allow(""),
	customer_email: J.string().allow(""),
	customer_login: J.string().allow(""),
	customer_number: J.string().allow(""),
	customer_password: J.string().allow(""), // WHAT??
	manufacturer_code: J.string(),
	transmit_face_form_angle: J.boolean(),
	ilog_access: J.number(),
	oauth2_credentials: J.object({
		access_token: J.string().allow(""),
		login_time: J.string().allow(""),
		refresh_token: J.string().allow(""),
	}).unknown(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_hoyasettings";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
