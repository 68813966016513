import { SVGProps } from "react";

const SvgTick = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 36}
		height={props.height || 27}
		viewBox="0 0 36 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 21.4002L3.60005 13.0002L0.800049 15.8002L12 27.0002L36 3.0002L33.2001 0.200195L12 21.4002Z"
			fill="#2D2926"
		/>
	</svg>
);

export default SvgTick;
