import SolidButton from "./SolidButton";
import TextButton from "./TextButton";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";

type FloatingButtonSingleType = {
	num: 1;
	onClick: () => void;
	label: string;
	disabled?: boolean;
	loading?: boolean;
};

type FloatingButtonDoubleType = {
	num: 2;
	col?: boolean;
	buttons: {
		left: {
			label: string;
			onClick: () => void;
			disabled?: boolean;
		};
		right: {
			label: string;
			disabled?: boolean;
			form?: string;
			loading?: boolean;
			onClick?: () => void;
		};
	};
};

type FloatingButtonType = {
	mx?: boolean;
} & (FloatingButtonSingleType | FloatingButtonDoubleType);

const FloatingButton = (props: FloatingButtonType) => {
	const { branding } = useBrandingStore();

	return (
		<div className={cn("py-3", props.mx && "px-6")}>
			{props.num === 1 && (
				<div className="grid grid-cols-2 items-center gap-8">
					<SolidButton
						color="primary"
						onClick={props.onClick}
						fullWidth
						className="col-start-2"
						disabled={props.disabled}
						loading={props.loading ? true : undefined}
					>
						{props.label}
					</SolidButton>
				</div>
			)}

			{props.num === 2 && (
				<div
					className={cn(
						"grid items-center",
						props.col ? "grid-cols-1 gap-5" : "grid-cols-2 gap-8",
					)}
				>
					<TextButton
						color="primary"
						onClick={props.buttons.left.onClick}
						disabled={props.buttons.left.disabled}
						fullWidth
						className={cn(
							props.col &&
								branding !== "hoya" &&
								"!h-10 [grid-area:2/1/2/1]",
							branding !== "hoya" &&
								"rounded-full border border-primary-100",
						)}
					>
						{props.buttons.left.label}
					</TextButton>
					<SolidButton
						color="primary"
						onClick={props.buttons.right.onClick}
						disabled={props.buttons.right.disabled}
						form={props.buttons.right.form}
						submit={props.buttons.right.form ? true : undefined}
						loading={props.buttons.right.loading ? true : undefined}
						fullWidth
						className={cn(
							props.col &&
								branding !== "hoya" &&
								"[grid-area:1/1/1/1]",
						)}
					>
						{props.buttons.right.label}
					</SolidButton>
				</div>
			)}
		</div>
	);
};

export default FloatingButton;
