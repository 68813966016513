import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	SettingsConnectivityStatus,
	SettingsFragment,
} from "../../components/fragments";
import {
	Checkbox,
	CustomSelect,
	Switch,
	TextField,
} from "../../components/input";
import { SelectOption } from "../../components/input/CustomSelect";
import { FrameLayout } from "../../components/layouts";
import { ModalBase, ModalLoading } from "../../components/modals";
import { BodyText, ButtonText, HeadingText } from "../../components/typography";
import {
	useHeaderStore,
	useSnackStore,
	getAccessIdAsPromise,
	useBrandingStore,
} from "../../store";
import {
	apChannelAutoconfig,
	changeAdminPassword,
	getCommSettings,
	getInternetConnectionState,
	getSSIDs,
	getSettings,
	setApPassword,
	setAutoconfigApChannel,
	setSettings,
	setUpdateEnabled,
	setVPN,
	wifiConnect,
	wifiDisconnect,
	testServerPresence,
} from "../../util/api/api-store";
import { unwrapFetchResult } from "../../util/result";
import { InfoPanel } from "../../components/panels";
import { Close, Done, Dot } from "../../components/icons";
import { cn } from "../../util/helpers";

type ApPasswordChangeProcess = {
	password: string;
	started: boolean;
	errorOccured: boolean;
	finished: boolean;
};

const Settings = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const { loggedOut } = useHeaderStore();
	const [modalLeaveOpen, setmodalLeaveOpen] = useState<boolean>(false);
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [ssdisOptions, setSsdisOptions] = useState<SelectOption[]>([]);
	const [selectedSsid, setSelectedSsid] = useState<string>("");
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isConnectingFailed, setConnectingFailed] = useState<boolean>(false);
	const [modalPasswordOpen, setModalPasswordOpen] = useState<boolean>(false);
	const [hasEthernet, setHasEthernet] = useState<boolean>(false);
	const [newPassword, setNewPassword] = useState({
		access_id: "",
		password: "",
	});
	const openSnack = useSnackStore((state) => state.open);
	const getCommSettingsResponse = getCommSettings.useGetResult();
	const [hasLeftApPasswordField, setHasLeftApPasswordField] =
		useState<boolean>(false);
	const [apPasswordChangeProcess, setApPasswrodChangeProcess] =
		useState<ApPasswordChangeProcess>({
			password: "",
			started: false,
			errorOccured: false,
			finished: false,
		});
	const [formData, setFormData] = useState({
		inet_connected: false,
		update_enabled: false,
		unattended_update: false, //@todo ????
		autoconfig_ap_channel: false,
		autoconfig_ap_channel_at_start: false,
		ap_channel: 0,
		inet_config: {
			SSID: "",
			key: "",
		},
		vpn_on: false,
	});
	const [formGeneralData, setFormGeneralData] = useState({
		close_active_measurement: false,
		inactive_time: 0,
	});
	const { state } = useLocation();
	const [isRefreshing, setIsRefreshing] = useState(false);

	const apPasswordValidty = [
		{
			valid: /[a-z]/.test(apPasswordChangeProcess.password),
			message: t("wifi.lowercaseMessage"),
		},
		{
			valid: /[A-Z]/.test(apPasswordChangeProcess.password),
			message: t("wifi.uppercaseMessage"),
		},
		{
			valid: /[0-9]/.test(apPasswordChangeProcess.password),
			message: t("wifi.numberMessage"),
		},
		{
			valid:
				/^[a-zA-Z0-9!$%*+,-./:;_]*$/.test(
					apPasswordChangeProcess.password,
				) && /[!$%*+,-./:;_]/.test(apPasswordChangeProcess.password),
			message: t("wifi.specialCharacterMessage"),
		},
		{
			valid:
				apPasswordChangeProcess.password.length >= 8 &&
				apPasswordChangeProcess.password.length <= 30,
			message: t("wifi.lengthMessage"),
		},
	];

	const noChange = getSettings
		.getResult()
		.map(
			({ inactive_time }) =>
				formGeneralData.inactive_time === inactive_time,
		)
		.unwrapOrDefault(true);

	const commSettings = getCommSettingsResponse.unwrapOrDefault({
		SSIDs: [],
		admin_mode: false,
		ap_channel: 0,
		autoconfig_ap_channel: false,
		autoconfig_ap_channel_at_start: false,
		inet_config: {
			SSID: "",
			address: "",
			encryption: "",
		},
		inet_connected: false,
		update_enabled: false,
		vpn_on: false,
	});

	const refresh = () => {
		setIsRefreshing(true);
		testServerPresence.setRequest(null, true);
		testServerPresence.invalidate();
		testServerPresence
			.fetchData()
			.then((resp) => {
				if (resp.success) {
					navigate(0);
				}
			})
			.finally(() => {
				setIsRefreshing(false);
			});
	};

	const changeApPassword = () => {
		setApPasswrodChangeProcess((prevState) => ({
			...prevState,
			started: true,
		}));
		setApPassword.setRequest({
			ap_password: apPasswordChangeProcess.password,
		});

		const timeout = new Promise<never>((resolve, reject) => {
			setTimeout(resolve, 15000, { success: true });
		});

		Promise.race([timeout, setApPassword.fetchData()])
			.then((resp) => {
				console.log(resp);
				setApPasswrodChangeProcess((prevState) => ({
					...prevState,
					errorOccured: !resp.success,
					finished: resp.success,
				}));
			})
			.catch((error) => {
				console.error(error);
				setApPasswrodChangeProcess((prevState) => ({
					...prevState,
					errorOccured: true,
				}));
			});
	};

	const setApChannelAutoconfig = () => {
		setIsSubmited(true);
		apChannelAutoconfig.setRequest({ action: "autoconfig" });
		apChannelAutoconfig.fetchData().then((resp) => {
			setFormData({
				...formData,
				ap_channel: Number(resp.ap_channel),
			});
			setIsSubmited(false);
		});
	};

	const toggleSetAutoconfigApChannel = (data: {
		autoconfig_ap_channel: boolean;
		autoconfig_ap_channel_at_start: boolean;
	}) => {
		setIsSubmited(true);
		setAutoconfigApChannel.setRequest({
			autoconfig_ap_channel: data.autoconfig_ap_channel,
			autoconfig_ap_channel_at_start: data.autoconfig_ap_channel_at_start,
		});
		setAutoconfigApChannel.fetchData().then((resp) => {
			setFormData({
				...formData,
				ap_channel: Number(resp.ap_channel),
				autoconfig_ap_channel: data.autoconfig_ap_channel,
				autoconfig_ap_channel_at_start:
					data.autoconfig_ap_channel_at_start,
			});
			setIsSubmited(false);
			getCommSettings.invalidate();
			getCommSettings.fetchData();
		});
	};

	const connect = () => {
		setIsSubmited(true);
		setConnectingFailed(false);
		wifiConnect.setRequest(
			{
				// inet_connected: formData.inet_connected,
				// update_enabled: formData.update_enabled,
				// unattended_update: false,//@todo ????
				// autoconfig_ap_channel: formData.autoconfig_ap_channel,
				// autoconfig_ap_channel_at_start: formData.autoconfig_ap_channel_at_start,
				// ap_channel: formData.ap_channel,
				inet_config: {
					SSID: formData.inet_config.SSID,
					key: formData.inet_config.key,
					encryption: "psk2",
				},
			},
			true,
		);
		wifiConnect.invalidate();
		wifiConnect
			.fetchData()
			.then((resp) => {
				if (resp.success) {
					setModalPasswordOpen(false);
					getCommSettings.invalidate();
					setWifi();
				} else {
					setConnectingFailed(true);
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setIsSubmited(false));
	};

	const disconnect = () => {
		setIsSubmited(true);
		wifiDisconnect.setRequest(null, true);
		wifiDisconnect.invalidate();
		wifiDisconnect
			.fetchData()
			.then(() => {
				setSelectedSsid("");
				setIsConnected(false);

				getCommSettings.invalidate();
				setWifi();
			})
			.finally(() => setIsSubmited(false));
	};

	const setWifi = () => {
		getCommSettings.fetchData().then((resp) => {
			const ssids = resp.SSIDs
				? resp.SSIDs.map((e) => ({ value: e, label: e }))
				: [];
			setSsdisOptions(ssids);

			if (resp.inet_connected) {
				setSelectedSsid(resp.inet_config?.SSID);
				setIsConnected(true);
			}

			setFormData({
				inet_connected: resp.inet_connected,
				update_enabled: resp.update_enabled,
				unattended_update: false, //@todo ????
				autoconfig_ap_channel: resp.autoconfig_ap_channel,
				autoconfig_ap_channel_at_start:
					resp.autoconfig_ap_channel_at_start,
				ap_channel: resp.ap_channel,
				inet_config: {
					SSID: resp.inet_config?.SSID ? resp.inet_config.SSID : "",
					key: "",
				},
				vpn_on: resp.vpn_on,
			});
		});
	};

	const onSubmitNewPassword = () => {
		setIsSubmited(true);

		changeAdminPassword.setRequest(newPassword);
		changeAdminPassword.fetchData().then((resp) => {
			setIsSubmited(false);
			setNewPassword((current) => ({
				...current,
				password: "",
			}));
			getSettings.setRequest(null);
			getSettings.invalidate();
			getSettings.fetchData();
			openSnack(t("settings.passwordSavedSuccessfully"), "ok");
		});
	};

	const closeLeaveModal = () => {
		setmodalLeaveOpen(false);
	};

	const closePasswordModal = () => {
		setModalPasswordOpen(false);
		setSelectedSsid("");
		setIsSubmited(false);
		setConnectingFailed(false);
	};

	const onSubmitGeneral = () => {
		setIsSubmited(true);
		const getSettingsResp = unwrapFetchResult(getSettings.getResult());
		const settings = {
			auto_exposure: getSettingsResp.auto_exposure ? 1 : 0,
			contrast: getSettingsResp.contrast,
			direct_loupe: getSettingsResp.direct_loupe,
			exposure_time: getSettingsResp.exposure_time,
			eye_catcher: getSettingsResp.eye_catcher,
			history_show_preview: getSettingsResp.history_show_preview,
			inactive_time: formGeneralData.close_active_measurement
				? formGeneralData.inactive_time
				: 0,
			starlight_enabled: getSettingsResp.starlight_enabled,
			onboard: getSettingsResp.onboard,
		};
		setSettings.setRequest(settings);
		setSettings.fetchData().then(() => {
			getSettings.invalidate();
			getSettings.fetchData().then((resp) => {
				setFormGeneralData({
					close_active_measurement:
						resp.inactive_time === 0 ? false : true,
					inactive_time: resp.inactive_time,
				});
			});
			setIsSubmited(false);
			openSnack(t("settings.adminSettingsSavedSuccessfully"), "ok");
			navigate("/");
			loggedOut();
		});
	};

	useEffect(() => {
		if (!state?.loggedIn) {
			navigate("/admin/login");
		}
	}, []);

	useEffect(() => {
		document.title = t("settings.title");

		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	useEffect(() => {
		const unSub = useHeaderStore.subscribe((state) => {
			console.log(state);

			if (state.logout) {
				console.log("logout");
				console.log(formGeneralData);
				console.log(
					getSettings.getResult().unwrapOrDefaultGeneric(true),
				);
				console.log(noChange);
				// @todo
				if (!noChange) {
					setmodalLeaveOpen(true);
					return;
				}
				navigate("/");
				loggedOut();
			}
		});
		return () => unSub();
	}, [formGeneralData]);

	useEffect(() => {
		getAccessIdAsPromise().then((access_id) => {
			setNewPassword({
				access_id: access_id,
				password: "",
			});
		});

		getCommSettings.setRequest(null);
		setWifi();

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			// @todo csak akkor jut ide, ha tenyleg be van lepve!
			if (!resp.admin_mode) {
				navigate("/admin");
				return;
			}
			setFormGeneralData({
				close_active_measurement:
					resp.inactive_time === 0 ? false : true,
				inactive_time: resp.inactive_time,
			});
		});

		getSSIDs.setRequest(null);
		getSSIDs.fetchData().then((resp) => {
			const ssids = resp.SSIDs
				? resp.SSIDs.map((e) => ({ value: e, label: e }))
				: [];
			setSsdisOptions(ssids);
		});
	}, []);

	useEffect(() => {
		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasEthernet(resp.internet_connected);
			})
			.catch(() => {
				setHasEthernet(false);
			});
	}, []);

	return (
		<FrameLayout hiddenFooter={true} headerType="admin">
			<div className={cn("container pt-12 md:pt-16")}>
				<div className={cn("flex flex-col gap-12")}>
					<HeadingText
						h={2}
						className={cn(
							branding !== "hoya" &&
								"!text-[26px] !leading-normal",
						)}
					>
						{t("settings.pageTitle")}
					</HeadingText>
					<SettingsConnectivityStatus type="success">
						<strong>{t("settings.adminActive")}</strong>
					</SettingsConnectivityStatus>
					<hr className="bg-secondary-40" />
				</div>
				<SettingsFragment
					borderBottom
					borderTop={branding === "hoya"}
					className={cn(branding !== "hoya" && "py-10")}
				>
					<div className={cn("flex flex-col gap-6")}>
						<ButtonText type="normal">
							{t("settings.changePasswordDescription")}
						</ButtonText>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								onSubmitNewPassword();
							}}
							className={cn(
								branding !== "hoya"
									? "flex flex-col gap-8"
									: "grid gap-6 sm:items-end md:grid-cols-8",
							)}
						>
							<div className={cn("md:col-span-4 lg:col-span-3")}>
								<TextField
									htmlFor="admin-new-password"
									type="default"
									showPasswordButton={true}
									fieldOptions={{ type: "password" }}
									label={t("settings.newPassword")}
									value={newPassword.password}
									setValue={(v) =>
										setNewPassword({
											...newPassword,
											password: v,
										})
									}
									fullWidth
									className={cn(
										branding !== "hoya" &&
											"md:grid-cols-[1fr_292px]",
									)}
								/>
							</div>
							<div
								className={cn(
									"md:col-span-4 lg:col-span-5",
									branding !== "hoya" && "self-end",
								)}
							>
								{branding !== "hoya" ? (
									<SolidButton
										color="primary"
										submit={true}
										disabled={isSubmited}
									>
										{t("settings.changePassword")}
									</SolidButton>
								) : (
									<OutlineButton
										color="primary"
										disabled={isSubmited}
										submit={true}
									>
										{t("settings.changePassword")}
									</OutlineButton>
								)}
							</div>
						</form>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("settings.generalSettings") || undefined}
					borderBottom
					className={cn(branding !== "hoya" && "py-10")}
				>
					{branding !== "hoya" ? (
						<Switch
							value={
								formGeneralData.close_active_measurement
									? true
									: false
							}
							setValue={() =>
								// @todo !close_active_measurement ? 0 : inactive_time
								setFormGeneralData({
									...formGeneralData,
									close_active_measurement:
										!formGeneralData.close_active_measurement,
									inactive_time:
										formGeneralData.close_active_measurement
											? 0
											: formGeneralData.inactive_time,
								})
							}
							label={t("settings.closeActiveMeasurement")}
							info={
								t(
									"settings.closeActiveMeasurementDescription",
								) || undefined
							}
						/>
					) : (
						<Checkbox
							label={t("settings.closeActiveMeasurement")}
							info={
								t(
									"settings.closeActiveMeasurementDescription",
								) || undefined
							}
							checked={
								formGeneralData.close_active_measurement
									? "checked"
									: "unchecked"
							}
							setChecked={() => {
								// @todo !close_active_measurement ? 0 : inactive_time
								setFormGeneralData({
									...formGeneralData,
									close_active_measurement:
										!formGeneralData.close_active_measurement,
									inactive_time:
										formGeneralData.close_active_measurement
											? 0
											: formGeneralData.inactive_time,
								});
							}}
						/>
					)}
					{formGeneralData.close_active_measurement && (
						<div
							className={cn(
								"mt-6 grid items-end gap-6 md:grid-cols-8",
								branding !== "hoya" ? "" : "pl-9",
							)}
						>
							<div
								className={cn(
									branding !== "hoya"
										? "col-span-full"
										: "md:col-span-4 lg:col-span-3",
								)}
							>
								<CustomSelect
									label={t("settings.closeAfter")}
									value={formGeneralData.inactive_time.toString()}
									fullWidth
									disabled={
										!formGeneralData.close_active_measurement
									}
									setValue={(v) => {
										setFormGeneralData({
											...formGeneralData,
											inactive_time: parseInt(v),
										});
									}}
									options={[
										{
											value: "60",
											label: t("settings.closeAfter60"),
										},
										{
											value: "90",
											label: t("settings.closeAfter90"),
										},
										{
											value: "120",
											label: t("settings.closeAfter120"),
										},
									]}
								/>
							</div>
						</div>
					)}
				</SettingsFragment>
				<SettingsFragment
					title={t("settings.connectLocal") || undefined}
					borderBottom
					className={cn(branding !== "hoya" && "py-10")}
				>
					<div className={cn("flex flex-col gap-8")}>
						<BodyText
							type="normal"
							className="text-primary-dark-80"
						>
							{t("settings.connectLocalDescription")}
						</BodyText>
						{isConnected ? (
							<SettingsConnectivityStatus type="success">
								<strong>
									{hasEthernet
										? t("settings.connectedToWifi")
										: t(
												"settings.connectedToWifiNoInternet",
											)}
								</strong>
							</SettingsConnectivityStatus>
						) : (
							<SettingsConnectivityStatus type="warning">
								<strong>
									{t("settings.notConnectedToWifi")}
								</strong>
							</SettingsConnectivityStatus>
						)}
						{!isConnected && hasEthernet && (
							<InfoPanel
								type="info"
								title={t("settings.connectedByCable")}
								message={
									t("settings.connectedByCableDescription") ||
									undefined
								}
							/>
						)}
						<div
							className={cn(
								branding !== "hoya"
									? "flex flex-col gap-8"
									: "grid gap-6 sm:items-end md:grid-cols-8",
							)}
						>
							<div className={cn("md:col-span-4 lg:col-span-3")}>
								<CustomSelect
									label={t("settings.network")}
									fullWidth
									value={selectedSsid}
									defaultLabel={
										t("settings.networkDescription") ||
										undefined
									}
									setValue={(v) => {
										// setSelectedSsid(v);
										setFormData({
											...formData,
											inet_config: {
												SSID: v,
												key: "",
											},
										});
										setModalPasswordOpen(true);
									}}
									disabled={isSubmited}
									options={ssdisOptions}
									menuOpened={() => {
										getSSIDs.invalidate();
										getSSIDs.fetchData().then((resp) => {
											const ssids = resp.SSIDs
												? resp.SSIDs.map((e) => ({
														value: e,
														label: e,
													}))
												: [];
											setSsdisOptions(ssids);
										});
									}}
								/>
							</div>
							{isConnected && (
								<div
									className={cn(
										"md:col-span-4 lg:col-span-5",
										branding !== "hoya" && "self-end",
									)}
								>
									{branding !== "hoya" ? (
										<SolidButton
											color="primary"
											onClick={disconnect}
											disabled={isSubmited}
										>
											{t("settings.disconnect")}
										</SolidButton>
									) : (
										<OutlineButton
											color="primary"
											onClick={disconnect}
											disabled={isSubmited}
										>
											{t("settings.disconnect")}
										</OutlineButton>
									)}
								</div>
							)}
						</div>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("settings.connectionToMaster") || undefined}
					borderBottom
					className={cn(branding !== "hoya" && "py-10")}
				>
					<div className={cn("flex flex-col gap-8")}>
						<BodyText
							type="normal"
							className="text-primary-dark-80"
						>
							{t("settings.connectionToMasterDescription")}
						</BodyText>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								changeApPassword();
							}}
							className={cn(
								branding !== "hoya"
									? "flex flex-col gap-8"
									: "grid gap-6 sm:items-end md:grid-cols-8",
							)}
						>
							<div className={cn("md:col-span-4 lg:col-span-3")}>
								<TextField
									type={
										hasLeftApPasswordField &&
										!apPasswordValidty.every((v) => v.valid)
											? "alert"
											: "default"
									}
									value={apPasswordChangeProcess.password}
									setValue={(newVal) =>
										setApPasswrodChangeProcess(
											(prevState) => ({
												...prevState,
												password: newVal,
											}),
										)
									}
									htmlFor="inputmodal"
									label={t("settings.newPassword")}
									showPasswordButton={true}
									fieldOptions={{
										onBlur: () =>
											setHasLeftApPasswordField(
												apPasswordChangeProcess.password
													.length > 0,
											),
										type: "password",
									}}
									fullWidth
								/>
							</div>
							{apPasswordChangeProcess.password.length > 0 && (
								<div className="col-span-full md:row-start-2">
									<BodyText type={"normal"}>
										{t("settings.passwordRequirements")}
									</BodyText>
									{apPasswordValidty.map((v, index) => (
										<div
											key={index}
											className={`flex flex-row ${
												v.valid
													? "text-system-success-100"
													: hasLeftApPasswordField
														? "text-system-danger-100"
														: "text-primary-dark-60"
											}`}
										>
											{v.valid ? (
												<Done
													className="m-[6px]"
													width={12}
													height={12}
												/>
											) : hasLeftApPasswordField ? (
												<Close
													className="m-[6px]"
													width={12}
													height={12}
												/>
											) : (
												<Dot
													className="m-[6px]"
													width={12}
													height={12}
												/>
											)}
											<BodyText
												type={"normal"}
												className="flex-auto"
											>
												{v.message}
											</BodyText>
										</div>
									))}
								</div>
							)}
							<div
								className={cn(
									"md:col-span-4 lg:col-span-5",
									branding !== "hoya" && "self-end",
								)}
							>
								<OutlineButton
									color="primary"
									submit={true}
									disabled={
										isSubmited ||
										!apPasswordValidty.every((v) => v.valid)
									}
								>
									{t("settings.changePasswordButton")}
								</OutlineButton>
							</div>
						</form>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("settings.wifiSettings") || undefined}
					borderBottom
					className={cn(branding !== "hoya" && "py-10")}
				>
					<div className={cn("mt-5 flex flex-col gap-4")}>
						<Switch
							label={t("settings.vpnOn")}
							value={formData.vpn_on}
							disabled={isSubmited}
							setValue={(v) => {
								setIsSubmited(true);
								setVPN.setRequest({ vpn_on: v });
								setVPN.fetchData().then(() => {
									setFormData({
										...formData,
										vpn_on: v,
									});
									setIsSubmited(false);
									getCommSettings.invalidate();
									getCommSettings.fetchData();
								});
							}}
							info={t("settings.vpnDescription") || undefined}
						/>
						<div
							className={cn(
								"relative flex flex-col items-start justify-between gap-y-3 md:flex-row",
							)}
						>
							<Switch
								label={t(
									"settings.automaticChannelOptimization",
								)}
								value={formData.autoconfig_ap_channel}
								disabled={isSubmited}
								setValue={(v) => {
									toggleSetAutoconfigApChannel({
										autoconfig_ap_channel: v,
										autoconfig_ap_channel_at_start:
											formData.autoconfig_ap_channel_at_start,
									});
								}}
								info={
									t(
										"settings.channelOptimizationDescription",
									) || undefined
								}
								className={cn(branding !== "hoya" && "w-full")}
							/>
							<BodyText
								type="normal"
								className={cn(
									branding !== "hoya"
										? "absolute right-16 top-1 text-quaternary-80"
										: "ml-12 shrink-0 text-primary-dark-80 md:ml-0",
								)}
							>
								{`${t("settings.channel")} ${
									formData.ap_channel
								}`}
							</BodyText>
						</div>
						<div
							className={cn(
								"relative flex flex-col items-start justify-between gap-y-3 md:flex-row",
							)}
						>
							<Switch
								label={t(
									"settings.automaticChannelOptimizationAutomatic",
								)}
								value={formData.autoconfig_ap_channel_at_start}
								disabled={isSubmited}
								setValue={(v) => {
									toggleSetAutoconfigApChannel({
										autoconfig_ap_channel:
											formData.autoconfig_ap_channel,
										autoconfig_ap_channel_at_start: v,
									});
								}}
								info={
									t(
										"settings.automaticChanneOptimizationDescription",
									) || undefined
								}
								className={cn(branding !== "hoya" && "w-full")}
							/>
							<TextButton
								color="primary"
								ignorePadding
								disableRipple
								className={cn(
									branding !== "hoya"
										? "!absolute right-16 top-1"
										: "ml-12 md:ml-0",
								)}
								onClick={setApChannelAutoconfig}
								disabled={
									!formData.autoconfig_ap_channel_at_start ||
									isSubmited
								}
							>
								{t(
									"settings.automaticChannelOptimizationAtStart",
								)}
							</TextButton>
						</div>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("settings.updateSettings") || undefined}
					className={cn(branding !== "hoya" && "py-10")}
				>
					<Switch
						value={formData.update_enabled}
						setValue={(v) => {
							console.log(v);
							setIsSubmited(true);
							setUpdateEnabled.setRequest({
								update_enabled: v,
							});
							setUpdateEnabled.fetchData().then(() => {
								setFormData({
									...formData,
									update_enabled: v,
								});
								setIsSubmited(false);
							});
							getCommSettings.invalidate();
							getCommSettings.fetchData();
						}}
						label={t("settings.updateSettingsDescription")}
						info={t("settings.updateSettingsInfo") || undefined}
						disabled={isSubmited}
					/>
				</SettingsFragment>
				<div
					ref={buttonRef}
					className={cn(
						"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={clsx(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
						)}
					>
						<SolidButton
							color="primary"
							onClick={onSubmitGeneral}
							disabled={isSubmited || noChange}
						>
							{t("settings.saveNLogout")}
						</SolidButton>
					</div>
				</div>
			</div>
			<ModalBase
				disableHeader
				open={
					apPasswordChangeProcess.started &&
					!apPasswordChangeProcess.errorOccured
				}
				small
				close={() => undefined}
			>
				<ModalLoading
					title={t("settings.changingPassword")}
					text={t("settings.changingPasswordDescription")}
				/>
			</ModalBase>
			<ModalBase
				title={t("settings.failedToChangeWifiPassword") || undefined}
				open={apPasswordChangeProcess.errorOccured}
				small
				close={() =>
					setApPasswrodChangeProcess({
						...apPasswordChangeProcess,
						started: false,
						errorOccured: false,
					})
				}
			>
				<BodyText
					className={cn(
						branding !== "hoya"
							? "px-12 py-11 text-center"
							: "px-6 py-5",
					)}
					type={"normal"}
				>
					{t("settings.errorChangingWifiPassword")}
				</BodyText>
			</ModalBase>
			<ModalBase
				title={
					t("settings.successfullyChangedWifiPassword") || undefined
				}
				disableClose={true}
				open={apPasswordChangeProcess.finished}
				close={() => undefined}
			>
				<div
					className={cn(
						branding !== "hoya" ? "px-12 py-11" : "px-6 py-5",
					)}
				>
					<HeadingText h={5} className="mb-3">
						{t("settings.followSteps")}
					</HeadingText>
					<ol className="pl-4" style={{ listStyle: "auto" }}>
						<li>
							<BodyText type={"normal"}>
								{t("settings.goToWifiSettings")}
							</BodyText>
						</li>
						<li>
							<BodyText type={"normal"}>
								{t("settings.connectWithNewPassword")}
							</BodyText>
						</li>
						<li>
							<BodyText type={"normal"}>
								{t("settings.refreshPage")}
							</BodyText>
						</li>
					</ol>
					<p
						className={cn(
							"text-right",
							branding !== "hoya" ? "mt-8" : "mt-6",
						)}
					>
						<SolidButton
							color="primary"
							onClick={refresh}
							loading={isRefreshing}
						>
							{t("factoryReset.refresh")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
			<ModalBase
				title={
					isConnectingFailed
						? t("settings.failedToConnectToWifi", {
								ssid: formData.inet_config?.SSID,
							}) || undefined
						: t("settings.connectingToWifi", {
								ssid: formData.inet_config?.SSID,
							}) || undefined
				}
				open={modalPasswordOpen}
				small
				close={closePasswordModal}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						connect();
					}}
					className={cn(
						"flex flex-col",
						branding !== "hoya" ? "gap-10 px-12 py-10" : "p-6",
					)}
				>
					<TextField
						type="default"
						htmlFor="inputmodal"
						label={t("hoyasettings.customerPassword")}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						fullWidth
						value={formData.inet_config.key}
						setValue={(v) => {
							setFormData({
								...formData,
								inet_config: {
									SSID: formData.inet_config.SSID,
									key: v,
								},
							});
						}}
					/>
					<p className={cn(branding === "hoya" && "mt-8 text-right")}>
						<SolidButton
							color="primary"
							submit={true}
							loading={isSubmited}
							fullWidth={branding !== "hoya"}
						>
							{isSubmited
								? t("settings.connecting")
								: t("settings.connect")}
						</SolidButton>
					</p>
					{isConnectingFailed && (
						<InfoPanel
							type="info"
							title={t("settings.pleaseCheck")}
							message={
								t("settings.pleaseCheckDescription") ||
								undefined
							}
							className={cn(branding === "hoya" && "mt-6")}
						/>
					)}
				</form>
			</ModalBase>
			<ModalBase
				title={t("settings.leaveAdmin") || undefined}
				open={modalLeaveOpen}
				close={closeLeaveModal}
				small
			>
				<div
					className={cn(
						branding !== "hoya" ? "px-12 py-11 text-center" : "p-6",
					)}
				>
					<p className={cn(branding !== "hoya" ? "mb-8" : "mb-6")}>
						{t("settings.leaveAdminConfirm")}
					</p>
					<SolidButton
						color="primary"
						onClick={onSubmitGeneral}
						fullWidth
					>
						{t("settings.saveSettings")}
					</SolidButton>
					{branding !== "hoya" ? (
						<OutlineButton
							color="primary"
							className={cn("mt-4 text-primary-100")}
							onClick={() => {
								navigate("/");
								loggedOut();
							}}
							fullWidth
						>
							{t("settings.quitWithoutSave")}
						</OutlineButton>
					) : (
						<TextButton
							color="primary"
							className="mt-4 text-primary-100"
							onClick={() => {
								navigate("/");
								loggedOut();
							}}
							fullWidth
						>
							{t("settings.quitWithoutSave")}
						</TextButton>
					)}
				</div>
			</ModalBase>
		</FrameLayout>
	);
};

export default Settings;
