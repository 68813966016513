import { SVGProps } from "react";

const SvgRadioBtnEmptyFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<circle cx={12} cy={12} r={9} stroke={`unset`} strokeWidth={2} />
		<circle cx={12} cy={12} fill="#ffffff" r={7} />
	</svg>
);

export default SvgRadioBtnEmptyFilled;
