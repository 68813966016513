import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	FloatingButton,
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import {
	Add,
	Back,
	Calendar,
	Close,
	Download,
	Edit,
	IconDelete,
	MoreVertical,
	OSCalendar,
	OSDownload,
	OSPerson,
	OSPrint,
	Person,
	Print,
	Tick,
	Warning,
} from "../../components/icons";
import {
	Checkbox,
	CustomSelect,
	Datepicker,
	Switch,
	TextField,
} from "../../components/input";
import Frame1 from "../../components/measurement/result/Frame1";
import Frame2 from "../../components/measurement/result/Frame2";
import Sizing from "../../components/measurement/result/Sizing";
import OSSizing from "../../components/measurement/result/OSSizing";
import { ModalBase, ModalFullScreen } from "../../components/modals";
import { BodyText, HeadingText } from "../../components/typography";
import {
	calculate,
	sendEmail,
	setFinished,
	setFrameType,
	setLensType,
	getSessions,
	getSettings,
	setHeadRotation,
	setOrderId,
	listMeasurements,
	submitOrder,
	setSession,
	getHoyasettings,
	setHoyasettings,
} from "../../util/api/api-store";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import OutsideClickHandler from "react-outside-click-handler";
import { Dropdown } from "../../components/cards";
import { usePopper } from "react-popper";
import RequestStatus from "../../components/measurement/result/RequestStatus";
import { Option, newNone, newSome } from "../../util/result";
import { TableRowConsultation } from "../../components/table";
import {
	HoyaTarget,
	IResponseData as HoyaTargets,
} from "../../util/api/request-definitions/get-sessions";

import {
	FrameType,
	LensType,
} from "../../util/api/request-definitions/calculate";
import { cn, downloadXml, measurementOwnerHelper } from "../../util/helpers";
import { format } from "date-fns";
import deepEqual from "deep-equal";
import OSFrameFront from "../../components/measurement/result/OSFrameFront";
import OSFrameSide from "../../components/measurement/result/OSFrameSide";
import { useOAuth2 } from "@moon-packages/react-use-oauth2";

type SelectFrameType = "" | FrameType;

type Token = {
	access_id: string;
	measurement: string;
};

type FormData = {
	frameType: SelectFrameType;
	lensType: LensType;
	rotationCompensation: boolean;
};

type Status = "loading" | "done" | "error";

type SaveProcess = {
	sendToHoyaTarget: boolean;
	hoyaTarget: HoyaTarget | null;
	sendToSelf: boolean;
	sending: boolean;
	saved: Status;
	sentToHoyaTarget: Status;
	sentToSelf: Status;
};

const Frames = () => {
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const { state } = useLocation();
	const measurementId = useParams()?.measurement;
	const openSnack = useSnackStore((state) => state.open);
	const [modalRenameOpen, setModalRenameOpen] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [modalName, setModalName] = useState<string>("");
	const [modalSaveOpen, setModalSaveOpen] = useState<boolean>(false);
	const [modalExportOpen, setModalExportOpen] = useState<boolean>(false);
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);
	const [modalCIAMOpen, setModalCIAMOpen] = useState<boolean>(false);
	const [saveProcess, setSaveProcess] = useState<SaveProcess>({
		sendToHoyaTarget: window.localStorage.sendToHoyaTarget !== "0",
		hoyaTarget: null,
		sendToSelf: window.localStorage.sendToSelf !== "0",
		sending: false,
		saved: "loading",
		sentToHoyaTarget: "loading",
		sentToSelf: "loading",
	});
	const [token, setToken] = useState<Token>({
		access_id: "",
		measurement: measurementId ?? "",
	});
	const [formData, setFormData] = useState<FormData>({
		frameType: "",
		lensType: "progressive",
		rotationCompensation: true,
	});
	const [dates, setDates] = useState<{
		startDate: Option<Date>;
		endDate: Option<Date>;
	}>({ startDate: newNone(), endDate: newNone() });
	const [lastEdit, setLastEdit] = useState<number>(Date.now());
	const [searchOrederId, setSearchOrederId] = useState<string>("");
	const { b2bOpticDownload, orderIdFixed } = getSettings
		.useGetResult()
		.map((resp) => ({
			b2bOpticDownload: resp.modules.b2b_optic_download,
			orderIdFixed: resp.order_id_fixed,
		}))
		.unwrapOrDefault({ b2bOpticDownload: false, orderIdFixed: false });

	const getSessionsResponse = getSessions.useGetResult();
	const getSettingsResult = getSettings.useGetResult();
	const getHoyasettingsResult = getHoyasettings.useGetResult();
	const hoyaTargetList = getSessionsResponse.unwrapOrDefault([]);
	const [CIAMLoginState, setCIAMLoginState] = useState<
		"Success" | "Failed" | "Default"
	>("Default");

	const closeRenameModal = () => {
		setModalRenameOpen(false);
	};

	const saveName = () => {
		updateLastEdit();
		setOrderId.setRequest({
			...token,
			order_id: modalName,
		});
		setOrderId.fetchData().then((resp) => {
			console.log(resp);
			setName(modalName);
			listMeasurements.invalidate();
			closeRenameModal();
		});
	};

	const updateLastEdit = () => {
		setLastEdit(Date.now());
	};

	const updateCalculate = () => {
		calculate.invalidate();
		calculate.fetchData().then((resp) => {
			console.log(
				"roationCompensation: ",
				resp.head_rotation_compensation,
			);
			setFormData({
				...formData,
				frameType: resp.frame_type,
				lensType: resp.lens_type,
				rotationCompensation: resp.head_rotation_compensation,
			});
		});
	};

	const selectFrameType = (frameType: FrameType) => {
		updateLastEdit();
		setFormData((prevState) => ({
			...prevState,
			frameType: frameType,
		}));
		setFrameType.setRequest({
			...token,
			frame_type: frameType,
		});
		setFrameType.fetchData().then(updateCalculate);
	};

	const selectLensType = (lensType: LensType) => {
		updateLastEdit();
		setFormData((prevState) => ({
			...prevState,
			lensType: lensType,
		}));
		setLensType.setRequest({
			...token,
			lens_type: lensType,
		});
		setLensType.fetchData().then(updateCalculate);
	};

	const selectHeadRotation = (value: boolean) => {
		updateLastEdit();
		setFormData((prevState) => ({
			...prevState,
			rotationCompensation: value,
		}));
		setHeadRotation.setRequest({
			...token,
			head_rotation_compensation: value,
		});
		setHeadRotation.fetchData().then(updateCalculate);
		getSettings.invalidate();
	};

	const openSaveModal = () => {
		updateLastEdit();
		if (formData.frameType === "") {
			openSnack(t("result.pickAFrameType"), "warning");
			return;
		}
		if (
			getHoyasettingsResult.happy &&
			getHoyasettingsResult.data.ilog_access === 2
		) {
			setModalCIAMOpen(true);
		} else {
			setModalSaveOpen(true);
		}
	};

	const saveWithoutLogin = () => {
		updateLastEdit();
		setModalCIAMOpen(false);
		setModalSaveOpen(true);
	};

	const finish = () => {
		updateLastEdit();

		if (
			getHoyasettingsResult.happy &&
			getHoyasettingsResult.data.ilog_access === 2
		) {
			window.localStorage.setItem(
				"sendToHoyaTarget",
				saveProcess.sendToHoyaTarget ? "1" : "0",
			);
		}

		window.localStorage.setItem(
			"sendToSelf",
			saveProcess.sendToSelf ? "1" : "0",
		);

		setSaveProcess((prevState) => ({ ...prevState, sending: true }));

		if (
			branding === "hoya" &&
			getHoyasettingsResult.happy &&
			getHoyasettingsResult.data.ilog_access === 2
		) {
			Promise.all([sendToHoya(), sendEmailToSelf(), sendFinished()]).then(
				done,
			);
		} else {
			Promise.all([sendEmailToSelf(), sendFinished()]).then(done);
		}
	};

	const done = () => {
		listMeasurements.invalidate();
		openSnack(
			saveProcess.sendToSelf || saveProcess.sendToHoyaTarget
				? t("result.savedAndSentMeasurement", { name })
				: t("result.savedMeasurement", { name }),
			"ok",
		);
		navigate(state?.previousPage ? `${state.previousPage}` : "/");
	};

	const sendFinished = () => {
		return new Promise<void>((resolve, reject) => {
			if (saveProcess.saved === "done") {
				resolve();
				return;
			}

			setSaveProcess((prevState) => ({
				...prevState,
				saved: "loading",
			}));

			setFinished.setRequest(token, true);
			setFinished.invalidate();
			setFinished
				.fetchData()
				.then(() => {
					setSaveProcess((prevState) => ({
						...prevState,
						saved: "done",
					}));
					resolve();
				})
				.catch(() => {
					setSaveProcess((prevState) => ({
						...prevState,
						saved: "error",
					}));
					reject();
				});
		});
	};

	const sendToHoya = () => {
		return new Promise<void>((resolve, reject) => {
			if (
				!saveProcess.sendToHoyaTarget ||
				saveProcess.sentToHoyaTarget === "done"
			) {
				resolve();
				return;
			}

			setSaveProcess((prevState) => ({
				...prevState,
				sentToHoyaTarget: "loading",
			}));

			if (saveProcess.hoyaTarget === null) {
				getSessions.setRequest(token, true);
				getSessions.invalidate();
				getSessions
					.fetchData()
					.then((_) => {
						submitOrder.setRequest({
							...token,
							action: "submit_order",
							head_rotation_compensation:
								formData.rotationCompensation,
						});
						submitOrder.invalidate();
						submitOrder.fetchData().then((_) => {
							setSaveProcess((prevState) => ({
								...prevState,
								sentToHoyaTarget: "done",
							}));
							resolve();
						});
					})
					.catch(() => {
						setSaveProcess((prevState) => ({
							...prevState,
							sentToHoyaTarget: "error",
						}));
						reject();
					});
			} else {
				setSession.setRequest(
					{
						...token,
						sessionId: saveProcess.hoyaTarget.sessionId,
						head_rotation_compensation:
							formData.rotationCompensation,
					},
					true,
				);
				setSession.invalidate();
				setSession
					.fetchData()
					.then((_) => {
						setSaveProcess((prevState) => ({
							...prevState,
							sentToHoyaTarget: "done",
						}));
						resolve();
					})
					.catch(() => {
						setSaveProcess((prevState) => ({
							...prevState,
							sentToHoyaTarget: "error",
						}));
						reject();
					});
			}
		});
	};

	const sendEmailToSelf = () => {
		return new Promise<void>((resolve, reject) => {
			if (!saveProcess.sendToSelf || saveProcess.sentToSelf === "done") {
				resolve();
				return;
			}

			setSaveProcess((prevState) => ({
				...prevState,
				sentToSelf: "loading",
			}));

			let lang = getCurrentLng();

			if (lang === "en-US") {
				lang = "en-GB";
			}

			sendEmail.setRequest(
				{
					...token,
					email_language: lang.replace("-", "_"),
					head_rotation_compensation: formData.rotationCompensation,
				},
				true,
			);
			sendEmail.invalidate();
			sendEmail
				.fetchData()
				.then(() => {
					setSaveProcess((prevState) => ({
						...prevState,
						sentToSelf: "done",
					}));
					resolve();
				})
				.catch(() => {
					setSaveProcess((prevState) => ({
						...prevState,
						sentToSelf: "error",
					}));
					reject();
				});
		});
	};

	const isSaveProcessFinished = () => {
		if (saveProcess.saved === "loading") {
			return false;
		}
		if (
			saveProcess.sendToHoyaTarget &&
			saveProcess.sentToHoyaTarget === "loading"
		) {
			return false;
		}
		if (saveProcess.sendToSelf && saveProcess.sentToSelf === "loading") {
			return false;
		}
		return true;
	};

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	useEffect(() => {
		// előző fázis:
		// create access_id
		// process measurement
		// editorchange page 2
		// access id beszerzése az előző fázisból
		console.log(renderData);
		console.log(state);
		if (!measurementId) {
			navigate(state?.previousPage ? `${state.previousPage}` : "/");
		}
		getAccessIdAsPromise().then((access_id) => {
			setToken((current) => ({
				...current,
				access_id: access_id,
			}));
			// const access_id = "ead97d18-e905-4d33-9632-fecd5ba99e71";
			// measurement beszerzése az előző fázisból
			// const measurement = "s708cp3-1650441315";
			// orderid beszerzése az előző fázisból
			// const orderID = "Barnabás";
			// setToken({access_id, measurement});
			// setName(orderID);

			calculate.setRequest({
				access_id: access_id,
				measurement: token.measurement,
			});
			calculate.invalidate();
			calculate
				.fetchData()
				.then((resp) => {
					console.log(resp);
					setFormData((prevState) => ({
						...prevState,
						frameType: resp.frame_type,
						lensType: resp.lens_type,
						rotationCompensation: resp.head_rotation_compensation,
					}));
					setName(resp.order_id);
					setModalName(resp.order_id);
				})
				.catch((err) => {
					console.log(err);
					navigate("/history");
					openSnack(err?.errorData?.error, "warning");
				});
		});
	}, []);

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData();
	}, []);

	const calculateResponse = calculate.useGetResult();
	const calculatedValue = calculateResponse.unwrapOrDefault({
		camera_pair: 0,
		diagnostic_images: {
			_CO: "",
			_CU: "",
		},
		frame_type: "metal",
		groove_depth: 0,
		head_rotation_compensation: true,
		last_modified: "",
		lens_type: "progressive",
		measurement: "",
		order_id: "",
		result_data: {
			head_rotation_compensation_off: {
				cornea_vertex_distance: 0,
				cornea_vertex_distance_L: 0,
				cornea_vertex_distance_R: 0,
				distance_between_lenses: 0,
				face_form_angle: 0,
				frame_height: 0,
				frame_width: 0,
				head_rotation_compensation_mm: 0,
				head_rotation_degrees: 0,
				hoya_mets: [0, 0, 0, 0],
				inclination: 0,
				left: [],
				lens_diameter_L: null,
				lens_diameter_R: null,
				measurement_distance_cm: 0,
				progressive_corridor_max_length: 0,
				pupil_distance: 0,
				right: [],
				y_aspheric_L: 0,
				y_aspheric_R: 0,
				zL_precise: 0,
				zR_precise: 0,
			},
			head_rotation_compensation_on: {
				cornea_vertex_distance: 0,
				cornea_vertex_distance_L: 0,
				cornea_vertex_distance_R: 0,
				distance_between_lenses: 0,
				face_form_angle: 0,
				frame_height: 0,
				frame_width: 0,
				head_rotation_compensation_mm: 0,
				head_rotation_degrees: 0,
				hoya_mets: [0, 0, 0, 0],
				inclination: 0,
				left: [0, 0],
				lens_diameter_L: null,
				lens_diameter_R: null,
				measurement_distance_cm: 0,
				progressive_corridor_max_length: 0,
				pupil_distance: 0,
				right: [0, 0],
				y_aspheric_L: 0,
				y_aspheric_R: 0,
				zL_precise: 0,
				zR_precise: 0,
			},
		},
		selection_images: {
			_CO: "",
			_CU: "",
		},
		time_stamp: "",
	});
	const renderData = formData.rotationCompensation
		? calculatedValue.result_data.head_rotation_compensation_on
		: calculatedValue.result_data.head_rotation_compensation_off;

	const addExistingConsultation = () => {
		getSessions.setRequest({
			access_id: token.access_id,
			measurement: token.measurement,
		});
		getSessions.fetchData().then((resp) => {
			console.log(resp);
			console.log("hoyaTargetList", hoyaTargetList);
			setModalExportOpen(true);
			// fetch(import.meta.env.VITE_MASTER_SERVER_HOST + "log/hoya_get_sessions.json")
			// 	.then((response) => response.json())
			// 	.then((json) => console.log(json));
		});
	};

	const filterHoyaTarget = (hoyaTarget: HoyaTarget): boolean => {
		const hoyaCreateDate = new Date(hoyaTarget.createTime);
		if (dates.startDate.happy && hoyaCreateDate < dates.startDate.data) {
			return false;
		}
		if (dates.endDate.happy && hoyaCreateDate > dates.endDate.data) {
			return false;
		}
		if (searchOrederId !== "" && hoyaTarget.commission !== searchOrederId) {
			return false;
		}

		return true;
	};

	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null,
	);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "top-end",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 7.5],
				},
			},
		],
	});

	measurementOwnerHelper(
		updateLastEdit,
		() => {
			navigate(state?.previousPage ? `${state.previousPage}` : "/");
		},
		calculateResponse.asOption().map((resp) => resp.measurement),
	);

	const { getAuth } = useOAuth2({
		authorizeUrl: `${
			import.meta.env.VITE_HOYA_OAUTH2_URL
		}/services/oauth2/authorize`,
		clientId: import.meta.env.VITE_HOYA_CLIENT_ID,
		redirectUri: `${document.location.origin}/#/hoya-token`,
		responseType: "code",
		scope: "api order order_session order_shipping_read",
		exchangeCodeForTokenQueryFn: async (callbackParameters: {
			code: string;
		}) => {
			console.log(callbackParameters);

			const jsonObject = {
				code: callbackParameters.code,
				grant_type: "authorization_code",
				redirect_uri: `${import.meta.env.VITE_HOYA_REDIRECT_URI}/#/hoya-token`,
				client_id: import.meta.env.VITE_HOYA_CLIENT_ID,
				client_secret: import.meta.env.VITE_HOYA_CLIENT_SECRET,
			};

			const formBody = [];

			for (const key in jsonObject) {
				formBody.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(
						jsonObject[key as keyof typeof jsonObject],
					)}`,
				);
			}
			console.log(formBody);

			const response = await fetch(
				`${import.meta.env.VITE_HOYA_OAUTH2_URL}/services/oauth2/token`,
				{
					method: "POST",
					body: formBody.join("&"),
					headers: {
						"Content-Type":
							"application/x-www-form-urlencoded;charset=UTF-8",
					},
				},
			);

			if (!response.ok)
				throw new Error("exchangeCodeForTokenQueryFn fail at example");
			const tokenData = await response.json();
			console.log(tokenData);
			return tokenData;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSuccess: (payload: any) => {
			console.log("Success", payload);

			getHoyasettings.invalidate();
			getHoyasettings.fetchData().then((hoyaResp) => {
				setHoyasettings.setRequest({
					...hoyaResp,
					ilog_access: hoyaResp.ilog_access,
					oauth2_credentials: {
						access_token: payload.access_token,
						login_time: payload.issued_at,
						refresh_token: payload.refresh_token,
					},
				});

				setHoyasettings
					.fetchData()
					.then(() => {
						getHoyasettings.invalidate();
						getHoyasettings.fetchData();

						setCIAMLoginState("Success");
						setTimeout(() => {
							setModalCIAMOpen(false);
							setModalSaveOpen(true);
							setCIAMLoginState("Default");
						}, 2000);
					})
					.catch((error) => {
						console.error(error);
						setCIAMLoginState("Failed");
					});
			});

			// const data = {
			// 	country_code: formData.country_code,
			// 	customer_email: formData.customer_email,
			// 	customer_login: formData.customer_login,
			// 	customer_number: formData.customer_number,
			// 	customer_password: formData.customer_password,
			// 	ciam_credentials: {
			// 		login: payload.access_token,
			// 		password: payload.refresh_token,
			// 	},
			// 	manufacturer_code: "hoy",
			// 	transmit_face_form_angle: true,
			// };

			// setHoyasettings.setRequest(data);
			// setHoyasettings
			// 	.fetchData()
			// 	.then(() => {
			// 		getHoyasettings.invalidate();
			// 		getHoyasettings.fetchData();

			// 		setCIAMSession({
			// 			accessToken: payload.access_token,
			// 			refreshToken: payload.refresh_token,
			// 			issuedAt: payload.issued_at,
			// 		});

			// 		setCIAMLoginState("Success");
			// 		setTimeout(() => {
			// 			setModalCIAMOpen(false);
			// 		}, 2000);

			// 		openSnack(t("profile.savedSuccessfully"), "ok");
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 		setSelectedOption("None");
			// 		setCIAMLoginState("Failed");
			// 	});
		},
		onError: (error_: string) => {
			console.error("Error", error_);
			setCIAMLoginState("Failed");
		},
	});

	return (
		<>
			<div
				className={cn(
					"fixed top-0 z-10 w-full shadow-[0px_1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88] print:hidden",
				)}
			>
				<div
					className={cn(
						"relative grid h-[72px] grid-cols-[minmax(48px,1fr)_auto_1fr] items-center px-6",
						branding !== "hoya" ? "container h-[85px]" : "h-[72px]",
					)}
				>
					<TextButton
						to={`/measurement/eyes/${token.measurement}/editor`}
						color="primary"
						icon={Edit}
						iconClasses={
							branding !== "hoya"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
								: undefined
						}
						className={cn(
							"-ml-3 rounded-full sm:rounded-none [&>span:not(.ripple)]:hidden sm:[&>span:not(.ripple)]:inline-block",
						)}
					>
						{t("result.editMeasurement")}
					</TextButton>
					<div
						className={cn(
							"col-start-2 flex flex-col gap-x-1 text-center sm:flex-row",
						)}
					>
						<BodyText type="bold16" className="line-clamp-2">
							{name}
						</BodyText>
						{!orderIdFixed && !state?.pms && (
							<button
								type="button"
								onClick={() => setModalRenameOpen(true)}
								className="text-primary-100"
							>
								{branding !== "hoya"
									? `(${t("result.rename")})`
									: t("result.rename")}
							</button>
						)}
					</div>
					<div
						className={cn(
							"flex items-center gap-3 justify-self-end sm:gap-6 print:gap-1",
						)}
					>
						<div className="grid">
							<TextButton
								icon={MoreVertical}
								color={"secondary"}
								iconClasses={
									branding !== "hoya"
										? "size-[24px] rotate-90"
										: undefined
								}
								onClick={() =>
									setDropdownVisible(!dropdownVisible)
								}
								forwardRef={setReferenceElement}
								className={cn(
									dropdownVisible
										? "bg-quaternary-20"
										: undefined,
									branding !== "hoya" &&
										"h-fit !rounded-[10px] border border-quaternary-80 p-[1.5px]",
								)}
							/>
							{dropdownVisible && (
								<OutsideClickHandler
									onOutsideClick={() =>
										setDropdownVisible(!dropdownVisible)
									}
								>
									<Dropdown
										forwardRef={setPopperElement}
										styles={styles.popper}
										attributes={{ ...attributes.popper }}
										className={cn(
											branding !== "hoya" &&
												"rounded-[10px] p-0 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]",
										)}
									>
										<TextButton
											icon={
												branding !== "hoya"
													? OSPrint
													: Print
											}
											color={"secondary"}
											fullWidth
											className={cn(
												"!justify-start [&>span]:font-normal",
												branding !== "hoya" &&
													"gap-9 border-b border-quaternary-100 p-4 font-bold last:border-none",
											)}
											onClick={() => window.print()}
										>
											{t("result.printCentration")}
										</TextButton>
										{!b2bOpticDownload && (
											<TextButton
												icon={
													branding !== "hoya"
														? OSDownload
														: Download
												}
												color={"secondary"}
												fullWidth
												className={cn(
													"!justify-start [&>span]:font-normal",
													branding !== "hoya" &&
														"gap-9 border-b border-quaternary-100 p-4 font-bold last:border-none",
												)}
												onClick={() => {
													downloadXml(
														`${
															import.meta.env
																.VITE_MASTER_SERVER_HOST
														}measurements/${
															token.measurement
														}.xml`,
														name,
														format(
															new Date(
																calculatedValue.time_stamp,
															),
															"yyyy-MM-dd",
														),
													);
													setDropdownVisible(false);
												}}
											>
												{t("result.downloadXml")}
											</TextButton>
										)}
									</Dropdown>
								</OutsideClickHandler>
							)}
						</div>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							iconClasses={
								branding !== "hoya" ? "size-[17px]" : undefined
							}
							onClick={() => {
								listMeasurements.invalidate();
								setModalLeaveOpen(true);
							}}
							className={cn(
								"justify-self-end",
								branding !== "hoya" &&
									"col-start-2 h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
							)}
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					"grid min-h-dvh grid-cols-1 grid-rows-[auto_1fr] print:h-fit print:min-h-fit print:pt-0",
					branding !== "hoya" ? "pt-[85px]" : "pt-[72px]",
				)}
			>
				<div className="container">
					<HeadingText h={5} className={cn("hidden print:block")}>
						{t("result.centrationProtocolTitle")}
					</HeadingText>
					{branding !== "hoya" ? (
						<div
							className={cn(
								"mx-auto flex w-fit items-center gap-8",
							)}
						>
							{name && (
								<div
									className={cn(
										"hidden overflow-hidden rounded-full bg-[#BBBEC31A] print:inline-flex",
									)}
								>
									<span
										className={cn(
											"flex h-[35px] items-center",
										)}
									>
										<div
											className={cn(
												"grid h-full place-items-center bg-primary-100 pl-4 pr-2.5",
											)}
										>
											<OSPerson />
										</div>
										<BodyText
											type="normal"
											className="px-5 py-1.5"
										>
											{name}
										</BodyText>
									</span>
								</div>
							)}
							<div
								className={cn(
									"my-6 grid w-fit grid-cols-[auto_1fr] items-center overflow-hidden rounded-full bg-[#BBBEC31A] print:my-2",
								)}
							>
								<div
									className={cn(
										"grid h-full place-items-center bg-primary-100 pl-3 pr-2",
									)}
								>
									<OSCalendar />
								</div>
								<BodyText
									type="normal"
									className={cn("px-5 py-1.5")}
								>
									<>
										{calculatedValue.time_stamp} /{" "}
										{calculatedValue.last_modified}
									</>
								</BodyText>
							</div>
						</div>
					) : (
						<BodyText
							type="small"
							className={cn(
								"my-6 flex items-center gap-3 bg-quaternary-20 px-3 py-2 text-primary-dark-60 print:mt-1 print:bg-transparent print:p-0 print:text-primary-dark-80",
							)}
						>
							<>
								{name && (
									<span
										className={cn(
											"mr-4 hidden items-center gap-3 print:inline-flex",
										)}
									>
										<Person width={16} height={16} />
										{name}
									</span>
								)}
								<Calendar width={16} height={16} />
								{calculatedValue.time_stamp} /{" "}
								{calculatedValue.last_modified}
							</>
						</BodyText>
					)}
					<hr
						className={cn(
							"mb-0 hidden border-black",
							branding === "hoya" && "print:block",
						)}
					/>
					<div
						className={cn(
							"mb-8 flex flex-col items-center justify-center sm:flex-row print:mb-0 print:flex print:flex-row",
						)}
					>
						{branding !== "hoya" ? (
							<>
								<OSFrameFront
									className={cn(
										"h-auto w-full shrink sm:w-fit print:max-h-[80px] print:w-auto",
									)}
									data={{
										frame_width_l:
											renderData.frame_width || 0,
										frame_width_r:
											renderData.frame_width || 0,
										frame_height_l:
											renderData.frame_height || 0,
										frame_height_r:
											renderData.frame_height || 0,
										pupill_to_frame_l:
											formData.lensType === "aspheric"
												? renderData.y_aspheric_R || 0
												: renderData.right[1] || 0,
										pupill_to_frame_r:
											formData.lensType === "aspheric"
												? renderData.y_aspheric_L || 0
												: renderData.left[1] || 0,
										pupill_to_nose_l:
											renderData.right[0] || 0,
										pupill_to_nose_r:
											renderData.left[0] || 0,
										lens_distance:
											renderData.distance_between_lenses ||
											0,
									}}
								/>
								<OSFrameSide
									className={cn(
										"h-auto w-full shrink sm:w-fit print:max-h-[80px] print:w-auto",
									)}
									data={{
										eye_to_lens_dist:
											renderData.cornea_vertex_distance ||
											0,
										lens_thickness:
											renderData.inclination || 0,
									}}
								/>
							</>
						) : (
							<>
								<div className="grid">
									<img
										src="/images/frame1.jpg"
										srcSet="/images/frame1@2x.jpg 2x"
										className="col-start-1 row-start-1"
										alt=""
										width="305"
										height="130"
									/>
									<Frame1
										className={cn(
											"col-start-1 row-start-1",
										)}
										data={{
											frame_width_l:
												renderData.frame_width || 0,
											frame_width_r:
												renderData.frame_width || 0,
											frame_height_l:
												renderData.frame_height || 0,
											frame_height_r:
												renderData.frame_height || 0,
											pupill_to_frame_l:
												formData.lensType === "aspheric"
													? renderData.y_aspheric_R ||
														0
													: renderData.right[1] || 0,
											pupill_to_frame_r:
												formData.lensType === "aspheric"
													? renderData.y_aspheric_L ||
														0
													: renderData.left[1] || 0,
											pupill_to_nose_l:
												renderData.right[0] || 0,
											pupill_to_nose_r:
												renderData.left[0] || 0,
											lens_distance:
												renderData.distance_between_lenses ||
												0,
										}}
									/>
								</div>
								<div className="grid">
									<img
										src="/images/frame2.jpg"
										srcSet="/images/frame2@2x.jpg 2x"
										className="col-start-1 row-start-1"
										alt=""
										width="166"
										height="130"
									/>
									<Frame2
										className={cn(
											"col-start-1 row-start-1",
										)}
										data={{
											eye_to_lens_dist:
												renderData.cornea_vertex_distance ||
												0,
											lens_thickness:
												renderData.inclination || 0,
										}}
									/>
								</div>
							</>
						)}
					</div>
					<div
						className={cn(
							"grid sm:grid-cols-2 print:flex",
							branding !== "hoya"
								? "mb-3 gap-6 sm:gap-3 print:gap-8"
								: "gap-x-12",
						)}
					>
						<SettingsFragment
							title={t("result.centrationValues") || undefined}
							borderBottom={branding === "hoya"}
							className={cn(
								"print:w-1/2 print:py-4",
								branding !== "hoya" &&
									"rounded-[20px] bg-[#BBBEC31A] p-5 print:bg-transparent print:p-0 [&>h5]:text-[22px] [&>h5]:text-primary-100",
							)}
						>
							<div
								className={cn(
									"flex flex-col gap-3 print:gap-0",
								)}
							>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.interpupillary distance")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
									>
										{renderData.pupil_distance?.toFixed(
											1,
										) || 0}
									</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{`${t("results.x")}${t(
											"result.rightLeft",
										)}`}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>{`${
										renderData.right[0]?.toFixed(1) || 0
									} | ${
										renderData.left[0]?.toFixed(1) || 0
									}`}</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{`${t("results.y (to box)")}${t(
											"result.rightLeft",
										)}`}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>{`${
										formData.lensType === "aspheric"
											? renderData.y_aspheric_R?.toFixed(
													1,
												) || 0
											: renderData.right[1]?.toFixed(1) ||
												0
									} | ${
										formData.lensType === "aspheric"
											? renderData.y_aspheric_L?.toFixed(
													1,
												) || 0
											: renderData.left[1]?.toFixed(1) ||
												0
									}`}</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.back-vertex-distance")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.cornea_vertex_distance?.toFixed(
											1,
										) || 0}
									</BodyText>
								</div>
								{branding !== "hoya" && (
									<div
										className={cn(
											"flex items-center justify-between gap-4",
										)}
									>
										<BodyText type="normal">
											{`${t(
												"results.back-vertex-distance",
											)}${t("result.rightLeft")}`}
										</BodyText>
										<BodyText
											type={"normal"}
											className="shrink-0"
										>
											{`${
												renderData.cornea_vertex_distance_R?.toFixed(
													1,
												) || 0
											} | ${
												renderData.cornea_vertex_distance_L?.toFixed(
													1,
												) || 0
											}`}
										</BodyText>
									</div>
								)}
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.inclination")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.inclination?.toFixed(1) ||
											0}
									</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.face form angle (°)")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.face_form_angle?.toFixed(
											1,
										) || 0}
									</BodyText>
								</div>
								{branding === "hoya" && (
									<div
										className={cn(
											"flex items-center justify-between gap-4",
										)}
									>
										<BodyText type="normal">
											{`${t("results.min diameter")}${t(
												"result.rightLeft",
											)}`}
										</BodyText>
										<BodyText
											type={"bold16"}
											className="shrink-0"
										>
											{`${
												renderData.lens_diameter_R &&
												renderData.lens_diameter_R !== 0
													? renderData.lens_diameter_R
													: t("results.n/a")
											} | ${
												renderData.lens_diameter_L &&
												renderData.lens_diameter_L !== 0
													? renderData.lens_diameter_L
													: t("results.n/a")
											}`}
										</BodyText>
									</div>
								)}
							</div>
						</SettingsFragment>
						<SettingsFragment
							title={t("result.frameDimensions") || undefined}
							borderBottom={branding === "hoya"}
							className={cn(
								"print:w-1/2 print:py-4",
								branding !== "hoya" &&
									"rounded-[20px] bg-[#BBBEC31A] p-5 print:bg-transparent print:p-0 [&>h5]:text-[22px] [&>h5]:text-primary-100",
							)}
						>
							<div
								className={cn(
									"flex flex-col gap-3 print:gap-0",
								)}
							>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.hor boxed lens size (A)")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.frame_width?.toFixed(1) ||
											0}
									</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.vert boxed lens size (B)")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.frame_height?.toFixed(1) ||
											0}
									</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t("results.distance between lenses")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.distance_between_lenses?.toFixed(
											1,
										) || 0}
									</BodyText>
								</div>
								{branding === "hoya" && (
									<div
										className={cn(
											"flex items-center justify-between gap-4",
										)}
									>
										<BodyText type="normal">
											{t("results.ABC-Mets")}
										</BodyText>
										<BodyText
											type={"bold16"}
											className="shrink-0"
										>
											{renderData.hoya_mets &&
											!deepEqual(
												renderData.hoya_mets,
												[0, 0, 0, 0],
											)
												? renderData.hoya_mets.toString()
												: t("results.n/a")}
										</BodyText>
									</div>
								)}
								<CustomSelect
									label={t("results.frame type")}
									value={formData.frameType}
									setValue={selectFrameType}
									options={[
										{
											label: t("frameType.metal"),
											value: "metal",
										},
										{
											label: t("frameType.plastic"),
											value: "plastic",
										},
										{
											label: t("frameType.rimless"),
											value: "rimless",
										},
										{
											label: t("frameType.nylor"),
											value: "nylor",
										},
										{
											label: t("frameType.optyl"),
											value: "optyl",
										},
										{
											label: t("frameType.spx"),
											value: "spx",
										},
									]}
									className="print:hidden"
									fullWidth
								/>
								<div
									className={cn(
										"hidden items-center justify-between gap-4 print:flex",
									)}
								>
									<BodyText type="normal">
										{t("results.frame type")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{t(`frameType.${formData.frameType}`)}
									</BodyText>
								</div>
							</div>
						</SettingsFragment>
					</div>
					<div
						className={cn(
							"grid sm:grid-cols-2 print:flex",
							branding !== "hoya"
								? "mb-3 gap-6 sm:gap-3 print:gap-8"
								: "gap-x-12",
						)}
					>
						<SettingsFragment
							title={t("result.headPosture") || undefined}
							className={cn(
								"print:w-1/2 print:py-4",
								branding !== "hoya" &&
									"rounded-[20px] bg-[#BBBEC31A] p-5 print:bg-transparent print:p-0 [&>h5]:text-[22px] [&>h5]:text-primary-100",
							)}
						>
							<div
								className={cn(
									"flex flex-col gap-3 print:gap-0",
								)}
							>
								<div
									className={cn(
										"flex flex-col gap-3 print:gap-0",
									)}
								>
									<div
										className={cn(
											"flex items-center justify-between gap-4",
										)}
									>
										<BodyText type="normal">
											{t("results.rotation")}
										</BodyText>
										<BodyText
											type={
												branding !== "hoya"
													? "normal"
													: "bold16"
											}
											className="shrink-0"
										>{`${
											renderData.head_rotation_degrees?.toFixed(
												1,
											) || 0
										} | ${
											renderData.head_rotation_compensation_mm?.toFixed(
												1,
											) || 0
										}`}</BodyText>
									</div>
								</div>
								<div
									className={cn(
										"flex flex-col gap-3 print:hidden",
									)}
								>
									<div
										className={cn(
											"flex items-center justify-between gap-4",
										)}
									>
										<BodyText type="normal">
											{t("results.rotation compensation")}
										</BodyText>
										<Switch
											value={
												formData.rotationCompensation
											}
											setValue={(newValue) =>
												selectHeadRotation(newValue)
											}
										/>
									</div>
								</div>
							</div>
						</SettingsFragment>
						<hr className={cn("m-0 sm:hidden")} />
						<SettingsFragment
							title={t("result.lens") || undefined}
							className={cn(
								"print:w-1/2 print:py-4",
								branding !== "hoya" &&
									"rounded-[20px] bg-[#BBBEC31A] p-5 print:bg-transparent print:p-0 [&>h5]:text-[22px] [&>h5]:text-primary-100",
							)}
						>
							<div
								className={cn(
									"flex flex-col gap-3 print:gap-0",
								)}
							>
								<CustomSelect
									label={t("results.lens type")}
									value={formData.lensType}
									setValue={selectLensType}
									options={[
										{
											value: "progressive",
											label: t("lensType.progressive"),
										},
										{
											value: "spherical",
											label: t("lensType.spherical"),
										},
										{
											value: "aspheric",
											label: t("lensType.aspheric"),
										},
									]}
									className="print:hidden"
									fullWidth
								/>
								<div
									className={cn(
										"hidden items-center justify-between gap-4 print:flex",
									)}
								>
									<BodyText type="normal">
										{t("results.lens type")}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{t(`lensType.${formData.lensType}`)}
									</BodyText>
								</div>
								<div
									className={cn(
										"flex items-center justify-between gap-4",
									)}
								>
									<BodyText type="normal">
										{t(
											"results.max progression zone length",
										)}
									</BodyText>
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "bold16"
										}
										className="shrink-0"
									>
										{renderData.progressive_corridor_max_length?.toFixed(
											1,
										) || 0}
									</BodyText>
								</div>
							</div>
						</SettingsFragment>
					</div>
					<div
						className={cn(
							"mt-8 hidden place-items-center print:grid",
						)}
					>
						{branding !== "hoya" ? (
							<OSSizing
								distance={renderData.distance_between_lenses}
								frameHeight={renderData.frame_height}
								lensWidth={renderData.frame_width}
								xRight={renderData.right[0]}
								yRight={renderData.right[1]}
								xLeft={renderData.left[0]}
								yLeft={renderData.left[1]}
							/>
						) : (
							<Sizing
								distance={renderData.distance_between_lenses}
								frameHeight={renderData.frame_height}
								lensWidth={renderData.frame_width}
								xRight={renderData.right[0]}
								yRight={renderData.right[1]}
								xLeft={renderData.left[0]}
								yLeft={renderData.left[1]}
							/>
						)}
					</div>
				</div>
				<div
					className={cn(
						"relative h-[72px] w-full self-end shadow-[0px_-1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88] print:hidden",
						branding !== "hoya" && "sticky bottom-0 h-fit",
					)}
				>
					<div
						className={cn(
							"container",
							branding !== "hoya" && "py-4",
						)}
					>
						<FloatingButton
							mx={false}
							num={2}
							col={branding !== "hoya" ? true : false}
							buttons={{
								left: {
									label: t("result.saveAsDraftAndQuit"),
									onClick: () =>
										navigate(
											state?.previousPage
												? `${state.previousPage}`
												: "/",
										),
								},
								right: {
									label: t("result.finish"),
									onClick: openSaveModal,
								},
							}}
						/>
					</div>
				</div>
			</div>
			<ModalBase
				title={t("result.leaveMeasurement") || undefined}
				open={modalLeaveOpen}
				close={() => {
					setModalLeaveOpen(false);
				}}
				small
			>
				<div
					className={cn(
						branding !== "hoya" ? "gap-10 px-12 py-10" : "p-6",
					)}
				>
					<p
						className={cn(
							branding !== "hoya" ? "mb-10 text-center" : "mb-6",
						)}
					>
						{t("result.leaveMeasurementConfirm")}
					</p>
					<SolidButton
						color="primary"
						onClick={() => {
							navigate(
								state?.previousPage
									? `${state.previousPage}`
									: "/",
							);
						}}
						fullWidth
					>
						{t("result.saveAsDraft")}
					</SolidButton>
					{branding !== "hoya" ? (
						<OutlineButton
							color="primary"
							className="mt-4 text-primary-100"
							onClick={() => {
								navigate(
									state?.previousPage
										? `${state.previousPage}`
										: "/",
								);
							}}
							fullWidth
						>
							{t("result.quitWithoutSave")}
						</OutlineButton>
					) : (
						<TextButton
							color="primary"
							className="mt-4 text-primary-100"
							onClick={() => {
								navigate(
									state?.previousPage
										? `${state.previousPage}`
										: "/",
								);
							}}
							fullWidth
						>
							{t("result.quitWithoutSave")}
						</TextButton>
					)}
				</div>
			</ModalBase>
			<ModalBase
				title={t("result.rename") || undefined}
				open={modalRenameOpen}
				close={closeRenameModal}
				small
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						saveName();
					}}
					className={cn(
						"flex flex-col",
						branding !== "hoya"
							? "gap-10 px-12 py-10"
							: "gap-6 p-6",
					)}
				>
					<TextField
						type="default"
						htmlFor="inputmodal"
						label={t("result.orderId")}
						value={modalName}
						setValue={(v) => setModalName(v)}
						fieldOptions={{ autoFocus: true }}
						clearable
						fullWidth
					/>
					<p className="text-right">
						<SolidButton
							color="primary"
							fullWidth={branding !== "hoya"}
							submit={true}
						>
							{t("result.rename")}
						</SolidButton>
					</p>
				</form>
			</ModalBase>
			<ModalBase
				title={
					saveProcess.sendToSelf || saveProcess.sendToHoyaTarget
						? t("result.saveAndSend") || undefined
						: t("result.save") || undefined
				}
				open={modalSaveOpen}
				close={() => setModalSaveOpen(false)}
				small
			>
				<div
					className={cn(
						"flex flex-col gap-6",
						branding !== "hoya" ? "px-12 py-10" : "p-6",
					)}
				>
					{saveProcess.sending ? (
						<>
							{
								<RequestStatus
									status={saveProcess.saved}
									loadingText={t("result.saving")}
									doneText={t("result.savedSuccessfully")}
									errorText={t("result.failedToSave")}
								/>
							}
							{branding === "hoya" && (
								<>
									{saveProcess.sendToHoyaTarget &&
										(getSettingsResult.happy &&
										getSettingsResult.data.branding !==
											"vs" ? (
											<RequestStatus
												status={
													saveProcess.sentToHoyaTarget
												}
												loadingText={t(
													"result.sendingValuesToHoya",
												)}
												doneText={t(
													"result.sentSuccessfullyToHoya",
												)}
												errorText={t(
													"result.failedToSendToHoya",
												)}
											/>
										) : (
											<RequestStatus
												status={
													saveProcess.sentToHoyaTarget
												}
												loadingText={t(
													"result.sendingValuesToLensSupplier",
												)}
												doneText={t(
													"result.sentSuccessfullyToLensSupplier",
												)}
												errorText={t(
													"result.failedToSendToLensSupplier",
												)}
											/>
										))}
								</>
							)}
							{saveProcess.sendToSelf && (
								<RequestStatus
									status={saveProcess.sentToSelf}
									loadingText={t(
										"result.sendingValuesToEmail",
									)}
									doneText={t(
										"result.sentSuccessfullyToEmail",
									)}
									errorText={t("result.failedToSendToEmail")}
								/>
							)}
							<p
								className={cn(
									"text-right",
									branding !== "hoya" && "w-full",
								)}
							>
								<SolidButton
									disabled={!isSaveProcessFinished()}
									color="primary"
									onClick={finish}
									fullWidth={branding !== "hoya"}
								>
									{t("result.tryAgain")}
								</SolidButton>
							</p>
						</>
					) : (
						<>
							<TextField
								type="disabled"
								htmlFor="inputmodal"
								label={t("results.orderID")}
								value={modalName}
								fullWidth
							/>

							{branding === "hoya" && (
								<>
									<Checkbox
										label={
											getSettingsResult.happy &&
											getSettingsResult.data.branding !==
												"vs"
												? t("result.sendToHoya")
												: t("result.sendToLensSupplier")
										}
										checked={
											saveProcess.sendToHoyaTarget &&
											(getHoyasettingsResult.happy &&
												getHoyasettingsResult.data
													.ilog_access) === 2
												? "checked"
												: "unchecked"
										}
										setChecked={() =>
											setSaveProcess((prevState) => ({
												...prevState,
												sendToHoyaTarget:
													!prevState.sendToHoyaTarget,
											}))
										}
										disabled={
											(getHoyasettingsResult.happy &&
												getHoyasettingsResult.data
													.ilog_access) !== 2
										}
									/>
									{saveProcess.sendToHoyaTarget &&
										(getHoyasettingsResult.happy &&
											getHoyasettingsResult.data
												.ilog_access) === 2 &&
										(saveProcess.hoyaTarget !== null ? (
											<>
												<div
													className={cn(
														"grid place-content-start gap-x-3 pl-9",
													)}
												>
													<p className="text-primary-dark-80">
														{t("result.addTo", {
															commission:
																saveProcess
																	.hoyaTarget
																	.commission,
														})}
													</p>
													<p
														className={cn(
															"row-start-2 text-primary-dark-80",
														)}
													>{`(${saveProcess.hoyaTarget.createTime})`}</p>
													<TextButton
														color="secondary"
														icon={IconDelete}
														className={cn(
															"col-start-2 row-span-2",
														)}
														onClick={() => {
															setSaveProcess(
																(
																	prevState,
																) => ({
																	...prevState,
																	hoyaTarget:
																		null,
																}),
															);
														}}
													/>
												</div>
												<TextButton
													color="primary"
													className="ml-7"
													onClick={() =>
														setModalExportOpen(true)
													}
												>
													{t("result.change")}
												</TextButton>
											</>
										) : (
											<>
												<TextButton
													icon={Add}
													color="primary"
													onClick={
														addExistingConsultation
													}
												>
													{t(
														"result.addToExistingConsultation",
													)}
												</TextButton>
											</>
										))}
								</>
							)}
							{(getHoyasettingsResult.happy &&
								getHoyasettingsResult.data.ilog_access) !==
								2 && (
								<BodyText
									type="mini"
									className={cn(
										"-mt-5 ml-9 text-primary-dark-60",
									)}
								>
									{t("result.hoyaModalText")}
								</BodyText>
							)}
							<Checkbox
								label={t("result.sendToEmail")}
								checked={
									saveProcess.sendToSelf
										? "checked"
										: "unchecked"
								}
								setChecked={() =>
									setSaveProcess((prevState) => ({
										...prevState,
										sendToSelf: !prevState.sendToSelf,
									}))
								}
							/>
							<p className="text-right">
								<SolidButton color="primary" onClick={finish}>
									{saveProcess.sendToSelf ||
									saveProcess.sendToHoyaTarget
										? t("result.saveAndSend")
										: t("result.save")}
								</SolidButton>
							</p>
						</>
					)}
				</div>
			</ModalBase>
			<ModalBase
				title={t("result.hoyaModalTitle")}
				open={modalCIAMOpen}
				close={() => setModalCIAMOpen(false)}
			>
				{CIAMLoginState === "Default" && (
					<div className={cn("flex flex-col gap-3 p-6")}>
						<p className="mb-3">{t("result.hoyaModalText")}</p>
						<OutlineButton onClick={getAuth} color="primary">
							{t("result.hoyaModalSignin")}
						</OutlineButton>
						<BodyText
							type="normal"
							className={cn(
								"h-fit w-fit self-center text-primary-dark-60",
							)}
						>
							{t("result.hoyaModalOr")}
						</BodyText>
						<OutlineButton
							onClick={saveWithoutLogin}
							color="primary"
						>
							{t("result.hoyaModalContinue")}
						</OutlineButton>
					</div>
				)}
				{CIAMLoginState === "Success" && (
					<div
						className={cn(
							"flex h-[19rem] flex-col items-center justify-center gap-6 px-6 py-12",
						)}
					>
						<div>
							<Tick />
						</div>
						<div className={cn("flex flex-col gap-2")}>
							<BodyText type="bold16" className="text-center">
								{t("result.hoyaModalSignedSucess")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								{t("result.hoyaModalWindowClose")}
							</BodyText>
						</div>
					</div>
				)}
				{CIAMLoginState === "Failed" && (
					<div
						className={cn(
							"flex h-[19rem] flex-col items-center justify-center gap-6 px-6 py-12",
						)}
					>
						<div>
							<Warning />
						</div>
						<div className={cn("flex flex-col gap-2")}>
							<BodyText type="bold16" className="text-center">
								{t("result.hoyaModalSignedFail")}
							</BodyText>
							<BodyText type="normal" className="text-center">
								{t("result.hoyaModalTryAgain")}
							</BodyText>
							<p className="mt-6">
								<SolidButton color="primary" onClick={getAuth}>
									{t("hoyasettings.customerLogin")}
								</SolidButton>
							</p>
						</div>
					</div>
				)}
			</ModalBase>
			<ModalFullScreen
				open={modalExportOpen}
				close={() => setModalExportOpen(false)}
				className={cn("grid grid-rows-[auto,1fr]")}
			>
				<div
					className={cn(
						"flex h-[72px] items-center bg-pure-white px-6 shadow-[0px_1px_0px_#e6e8e9]",
					)}
				>
					<TextButton
						color="primary"
						icon={Back}
						onClick={() => {
							setModalExportOpen(false);
						}}
						className={cn(
							"-ml-3 rounded-full sm:rounded-none [&>span:not(.ripple)]:hidden sm:[&>span:not(.ripple)]:inline-block",
						)}
					>
						{t("result.backToSaveAndSend")}
					</TextButton>
				</div>
				<div
					className={cn(
						"container grid grid-rows-[auto,auto,auto,1fr] overflow-hidden",
					)}
				>
					<HeadingText h={2} className="my-12">
						{t("result.addToConsultation")}
					</HeadingText>
					<div
						className={cn(
							"mb-6 grid grid-cols-1 gap-6 sm:grid-cols-2",
						)}
					>
						<Datepicker
							type={"default"}
							label={t("result.searchForDate")}
							htmlFor={"date"}
							startDate={dates.startDate.unwrapOrDefaultGeneric(
								null,
							)}
							endDate={dates.endDate.unwrapOrDefaultGeneric(null)}
							setValue={([startDate, endDate]) => {
								let optionStartDate;
								if (startDate === null) {
									optionStartDate = newNone<Date>();
								} else {
									optionStartDate = newSome(startDate);
								}
								let optionEndDate;
								if (endDate === null) {
									optionEndDate = newNone<Date>();
								} else {
									optionEndDate = newSome(endDate);
								}
								setDates({
									startDate: optionStartDate,
									endDate: optionEndDate,
								});
							}}
							placeholderText={
								t("result.dateFromDateTo") || undefined
							}
						/>
						<TextField
							type={"default"}
							fullWidth={true}
							label={t("result.searchForOrderId")}
							htmlFor={"orederId"}
							value={searchOrederId}
							fieldOptions={{
								placeholder: t("result.orderId") || "",
							}}
							clearable
							setValue={(search) => {
								console.log(search);
								setSearchOrederId(search);
							}}
						></TextField>
					</div>
					<div
						className={cn(
							"hidden justify-between border-b border-secondary-100 pb-3 sm:flex",
						)}
					>
						<BodyText
							type="normal"
							className="text-primary-dark-60"
						>
							{t("result.nameDate")}
						</BodyText>
						<BodyText
							type="normal"
							className="text-primary-dark-60"
						>
							{t("result.program")}
						</BodyText>
					</div>
					<div
						className={cn(
							"divide-y divide-secondary-40 overflow-y-auto",
						)}
					>
						{(hoyaTargetList as HoyaTargets)
							.filter(filterHoyaTarget)
							.map((item: HoyaTarget) => (
								<TableRowConsultation
									key={item.sessionId}
									content={{
										id: item.sessionId,
										name: item.commission,
										date: new Date(item.createTime),
										consultation: item.changeApp,
									}}
									isSelected={
										saveProcess.hoyaTarget?.sessionId
									}
									toggleSelection={(v) => {
										console.log(v);
										console.log(item);
										setSaveProcess((prevState) => ({
											...prevState,
											hoyaTarget: item,
										}));
										setModalExportOpen(false);
									}}
								/>
							))}
					</div>
				</div>
			</ModalFullScreen>
		</>
	);
};

export default Frames;
