import { useRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { newOption, newNone, newSome, Option } from "../../../util/result";
import * as drawing from "../../../util/drawing";
import {
	IrisRadii,
	FramesPositionsSingle,
	FramesPositionsSideSingle,
	FramesSelectedSideHandle as SelectedSideHandle,
	FramesSelectedCornerHandle as SelectedCornerHandle,
	SideHandle,
	CornerHandle,
	FramesHandle,
	Position,
	ImageUtils,
	LineProp,
	Box,
} from "../../../util/drawing";

export type FramesCanvasProps = {
	width: number;
	height: number;

	imageObject: HTMLImageElement;
	leftHandleObject: HTMLImageElement;
	rightHandleObject: HTMLImageElement;

	irisRadii: IrisRadii;
	irisPosition: { left: Position; right: Position };
	framePositions: FramesPositionsSingle;
	onFramesPositionChange: (p: FramesPositionsSingle) => void;
	onFramesPositionFinialized: (p: FramesPositionsSingle) => void;
	onEdgeSet: (e: CornerHandle) => void;
	imageUtils: ImageUtils;

	leftHand: boolean;
	alwaysUseLoupe: boolean;
	showSideHandles: boolean;
};

type CanvasState = {
	selectedSideHandle: Option<SelectedSideHandle>;
	selectedCornerHandle: Option<SelectedCornerHandle>;
	lastRapidPointerDownEvent: Option<number>;
};

const rapidTapTimeMS = 200;

const FramesCanvas = ({
	width,
	height,
	irisRadii,
	irisPosition,
	imageUtils,
	imageObject,
	leftHandleObject,
	rightHandleObject,
	leftHand,
	alwaysUseLoupe,
	framePositions,
	onFramesPositionChange,
	onFramesPositionFinialized,
	onEdgeSet,
	showSideHandles,
}: FramesCanvasProps) => {
	const { t } = useTranslation();
	const canvasRefNullable = useRef<HTMLCanvasElement>(null);
	const [error, setError] = useState<Option<string>>(newNone());

	const addToSetEdges = onEdgeSet;

	const [canvasState, setCanvasState] = useState<CanvasState>({
		selectedSideHandle: newNone(),
		selectedCornerHandle: newNone(),
		lastRapidPointerDownEvent: newNone(),
	});

	const handleColor = useMemo<string>(
		() =>
			getComputedStyle(document.body).getPropertyValue(
				"--system-highlight--100",
			),
		[],
	);

	const onPointerDownEventHandler =
		(canvasRef: HTMLCanvasElement) => (event: PointerEvent) => {
			const [
				bottomNasalBoxLeft,
				bottomBoxLeft,
				bottomTemporalBoxLeft,
				nasalBoxLeft,
				temporalBoxLeft,
				topNasalBoxLeft,
				topBoxLeft,
				topTemporalBoxLeft,
				bottomNasalBoxRight,
				bottomBoxRight,
				bottomTemporalBoxRight,
				nasalBoxRight,
				temporalBoxRight,
				topNasalBoxRight,
				topBoxRight,
				topTemporalBoxRight,
			] = [
				{
					ip: framePositions.left.bottomNasal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.bottom,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.bottomTemporal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.nasal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.temporal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.topNasal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.top,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.left.topTemporal,
					ir: irisRadii.left,
				},
				{
					ip: framePositions.right.bottomNasal,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.bottom,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.bottomTemporal,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.nasal,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.temporal,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.topNasal,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.top,
					ir: irisRadii.right,
				},
				{
					ip: framePositions.right.topTemporal,
					ir: irisRadii.right,
				},
			].map(({ ip, ir }) => {
				const iris = imageUtils.fromImageToCanvas(ip.x, ip.y);
				const irisDiam = imageUtils.scaleFromImageToCanvas(ir * 1.5);
				const handle = leftHand ? leftHandleObject : rightHandleObject;
				return drawing.calculateHandleBox(
					iris.x,
					iris.y,
					0,
					irisDiam,
					handle.height * (irisDiam / handle.width),
					!leftHand,
				);
			});

			const circleBoxClicked = [
				{
					pos: framePositions.left.bottomNasal,
					code: "left-bottom-nasal" as CornerHandle,
				},
				{
					pos: framePositions.left.bottomTemporal,
					code: "left-bottom-temporal" as CornerHandle,
				},
				{
					pos: framePositions.left.topNasal,
					code: "left-top-nasal" as CornerHandle,
				},
				{
					pos: framePositions.left.topTemporal,
					code: "left-top-temporal" as CornerHandle,
				},
				{
					pos: framePositions.right.bottomNasal,
					code: "right-bottom-nasal" as CornerHandle,
				},
				{
					pos: framePositions.right.bottomTemporal,
					code: "right-bottom-temporal" as CornerHandle,
				},
				{
					pos: framePositions.right.topNasal,
					code: "right-top-nasal" as CornerHandle,
				},
				{
					pos: framePositions.right.topTemporal,
					code: "right-top-temporal" as CornerHandle,
				},
			]
				.map(({ pos, code }) => {
					const cpos = imageUtils.fromImageToCanvasPos(pos);
					return {
						box: {
							x: cpos.x - 20,
							y: cpos.y - 20,
							width: 40,
							height: 40,
						},
						code,
					};
				})
				.reduce(
					(acc, circle) =>
						drawing.isInsideBox(
							{ x: event.offsetX, y: event.offsetY },
							circle.box,
						)
							? newSome(circle)
							: acc,
					newNone<{ box: Box; code: CornerHandle }>(),
				);

			if (circleBoxClicked.kind === "some") {
				setCanvasState({
					selectedSideHandle: newNone(),
					selectedCornerHandle: newSome({
						isTapped: false as boolean,
						handle: circleBoxClicked.data.code,
						previousPosition: {
							x: event.offsetX,
							y: event.offsetY,
						},
						doubleTap: false as boolean,
					}),
					lastRapidPointerDownEvent: newSome(Date.now()),
				});
			} else if (canvasState.selectedCornerHandle.kind === "none") {
				[
					{
						box: bottomBoxLeft,
						code: "left-bottom" as SideHandle,
					},
					...(showSideHandles
						? [
								{
									box: nasalBoxLeft,
									code: "left-nasal" as SideHandle,
								},
								{
									box: temporalBoxLeft,
									code: "left-temporal" as SideHandle,
								},
								{
									box: nasalBoxRight,
									code: "right-nasal" as SideHandle,
								},
								{
									box: temporalBoxRight,
									code: "right-temporal" as SideHandle,
								},
							]
						: []),
					{
						box: topBoxLeft,
						code: "left-top" as SideHandle,
					},
					{
						box: bottomBoxRight,
						code: "right-bottom" as SideHandle,
					},
					{
						box: topBoxRight,
						code: "right-top" as SideHandle,
					},
				].forEach(({ box, code }) => {
					if (
						drawing.isInsideBox(
							{ x: event.offsetX, y: event.offsetY },
							box,
						)
					) {
						setCanvasState({
							selectedSideHandle: newSome({
								handle: code,
								previousPosition: {
									x: event.offsetX,
									y: event.offsetY,
								},
								doubleTap: canvasState.lastRapidPointerDownEvent
									.map((t) => Date.now() - t < rapidTapTimeMS)
									.unwrapOrDefault(false),
							}),
							selectedCornerHandle: newNone(),
							lastRapidPointerDownEvent: newSome(Date.now()),
						});
						// addToSetEdges(code);
					}
				});
			} else if (canvasState.selectedCornerHandle.kind === "some") {
				let box;
				switch (canvasState.selectedCornerHandle.data.handle) {
					case "left-bottom-nasal":
						box = bottomNasalBoxLeft;
						break;
					case "left-bottom-temporal":
						box = bottomTemporalBoxLeft;
						break;
					case "left-top-nasal":
						box = topNasalBoxLeft;
						break;
					case "left-top-temporal":
						box = topTemporalBoxLeft;
						break;
					case "right-bottom-nasal":
						box = bottomNasalBoxRight;
						break;
					case "right-bottom-temporal":
						box = bottomTemporalBoxRight;
						break;
					case "right-top-nasal":
						box = topNasalBoxRight;
						break;
					case "right-top-temporal":
						box = topTemporalBoxRight;
						break;
				}
				if (
					drawing.isInsideBox(
						{ x: event.offsetX, y: event.offsetY },
						box,
					)
				) {
					setCanvasState({
						selectedSideHandle: newNone(),
						selectedCornerHandle: newSome({
							...canvasState.selectedCornerHandle.data,
							previousPosition: {
								x: event.offsetX,
								y: event.offsetY,
							},
							isTapped: true as boolean,
							doubleTap: canvasState.lastRapidPointerDownEvent
								.map((t) => Date.now() - t < rapidTapTimeMS)
								.unwrapOrDefault(false),
						}),
						lastRapidPointerDownEvent: newSome(Date.now()),
					});
					addToSetEdges(canvasState.selectedCornerHandle.data.handle);
				} else {
					setCanvasState({
						selectedSideHandle: newNone(),
						selectedCornerHandle: newNone(),
						lastRapidPointerDownEvent: newSome(Date.now()),
					});
				}
			}
		};

	const onPointerMoveEventHandler =
		(canvasRef: HTMLCanvasElement) => (event: PointerEvent) => {
			if (
				canvasState.selectedSideHandle.kind === "some" ||
				canvasState.selectedCornerHandle.kind === "some"
			) {
				if (canvasState.selectedSideHandle.kind === "some") {
					const selectedSideHandle =
						canvasState.selectedSideHandle.data;

					const movement = {
						x: imageUtils.scaleFromCanvasToImage(
							event.offsetX -
								selectedSideHandle.previousPosition.x,
						),
						y: imageUtils.scaleFromCanvasToImage(
							event.offsetY -
								selectedSideHandle.previousPosition.y,
						),
					};
					switch (selectedSideHandle.handle) {
						case "left-bottom":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									bottom: {
										x:
											framePositions.left.bottom.x +
											movement.x,
										y:
											framePositions.left.bottom.y +
											movement.y,
									},
								},
							});
							break;
						case "left-nasal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									nasal: {
										x:
											framePositions.left.nasal.x +
											movement.x,
										y:
											framePositions.left.nasal.y +
											movement.y,
									},
								},
							});
							break;
						case "left-temporal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									temporal: {
										x:
											framePositions.left.temporal.x +
											movement.x,
										y:
											framePositions.left.temporal.y +
											movement.y,
									},
								},
							});
							break;
						case "left-top":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									top: {
										x:
											framePositions.left.top.x +
											movement.x,
										y:
											framePositions.left.top.y +
											movement.y,
									},
								},
							});
							break;
						case "right-bottom":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									bottom: {
										x:
											framePositions.right.bottom.x +
											movement.x,
										y:
											framePositions.right.bottom.y +
											movement.y,
									},
								},
							});
							break;
						case "right-nasal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									nasal: {
										x:
											framePositions.right.nasal.x +
											movement.x,
										y:
											framePositions.right.nasal.y +
											movement.y,
									},
								},
							});
							break;
						case "right-temporal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									temporal: {
										x:
											framePositions.right.temporal.x +
											movement.x,
										y:
											framePositions.right.temporal.y +
											movement.y,
									},
								},
							});
							break;
						case "right-top":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									top: {
										x:
											framePositions.right.top.x +
											movement.x,
										y:
											framePositions.right.top.y +
											movement.y,
									},
								},
							});
							break;
					}
					setCanvasState({
						selectedSideHandle: newSome({
							...selectedSideHandle,
							previousPosition: {
								x: event.offsetX,
								y: event.offsetY,
							},
						}),
						selectedCornerHandle: newNone(),
						lastRapidPointerDownEvent: newNone(),
					});
				} else if (
					canvasState.selectedCornerHandle.kind === "some" &&
					canvasState.selectedCornerHandle.data.isTapped
				) {
					const handle = canvasState.selectedCornerHandle.data;
					const movement = {
						x: imageUtils.scaleFromCanvasToImage(
							event.offsetX - handle.previousPosition.x,
						),
						y: imageUtils.scaleFromCanvasToImage(
							event.offsetY - handle.previousPosition.y,
						),
					};
					switch (handle.handle) {
						case "left-bottom-nasal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									bottomNasal: {
										x:
											framePositions.left.bottomNasal.x +
											movement.x,
										y:
											framePositions.left.bottomNasal.y +
											movement.y,
									},
								},
							});
							break;
						case "left-bottom-temporal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									bottomTemporal: {
										x:
											framePositions.left.bottomTemporal
												.x + movement.x,
										y:
											framePositions.left.bottomTemporal
												.y + movement.y,
									},
								},
							});
							break;
						case "left-top-nasal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									topNasal: {
										x:
											framePositions.left.topNasal.x +
											movement.x,
										y:
											framePositions.left.topNasal.y +
											movement.y,
									},
								},
							});
							break;
						case "left-top-temporal":
							onFramesPositionChange({
								...framePositions,
								left: {
									...framePositions.left,
									topTemporal: {
										x:
											framePositions.left.topTemporal.x +
											movement.x,
										y:
											framePositions.left.topTemporal.y +
											movement.y,
									},
								},
							});
							break;
						case "right-bottom-nasal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									bottomNasal: {
										x:
											framePositions.right.bottomNasal.x +
											movement.x,
										y:
											framePositions.right.bottomNasal.y +
											movement.y,
									},
								},
							});
							break;
						case "right-bottom-temporal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									bottomTemporal: {
										x:
											framePositions.right.bottomTemporal
												.x + movement.x,
										y:
											framePositions.right.bottomTemporal
												.y + movement.y,
									},
								},
							});
							break;
						case "right-top-nasal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									topNasal: {
										x:
											framePositions.right.topNasal.x +
											movement.x,
										y:
											framePositions.right.topNasal.y +
											movement.y,
									},
								},
							});
							break;
						case "right-top-temporal":
							onFramesPositionChange({
								...framePositions,
								right: {
									...framePositions.right,
									topTemporal: {
										x:
											framePositions.right.topTemporal.x +
											movement.x,
										y:
											framePositions.right.topTemporal.y +
											movement.y,
									},
								},
							});
							break;
					}
					setCanvasState({
						selectedCornerHandle: newSome({
							...handle,
							previousPosition: {
								x: event.offsetX,
								y: event.offsetY,
							},
						}),
						selectedSideHandle: newNone(),
						lastRapidPointerDownEvent: newNone(),
					});
				}
				event.preventDefault();
				event.stopPropagation();
			}
		};

	const onPointerUpEventHandler =
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(_: HTMLCanvasElement) => (_: PointerEvent) => {
			setCanvasState((state) => ({
				...state,
				selectedSideHandle: newNone(),
				selectedCornerHandle: state.selectedCornerHandle.map((h) => ({
					...h,
					isTapped: false as boolean,
				})),
			}));
			onFramesPositionFinialized(framePositions);
		};

	useEffect(() => {
		const canvasRefOpt = newOption(canvasRefNullable.current);
		const canvasRef = canvasRefOpt.expect(
			"This is called after componentDidMount. CanvasRef is now not null.",
		);

		const downHandler = onPointerDownEventHandler(canvasRef);
		const moveHandler = onPointerMoveEventHandler(canvasRef);
		const upHandler = onPointerUpEventHandler(canvasRef);

		const scrollHandler = (event: Event) => {
			if (
				canvasState.selectedSideHandle.kind === "some" ||
				canvasState.selectedCornerHandle.kind === "some"
			) {
				event.preventDefault();
			}
		};

		canvasRef.addEventListener("pointerdown", downHandler);
		canvasRef.addEventListener("pointermove", moveHandler);
		canvasRef.addEventListener("pointerup", upHandler);

		window.addEventListener("touchmove", scrollHandler, { passive: false });

		return () => {
			canvasRef.removeEventListener("pointerdown", downHandler);
			canvasRef.removeEventListener("pointermove", moveHandler);
			canvasRef.removeEventListener("pointerup", upHandler);

			window.removeEventListener("touchmove", scrollHandler);
		};
	}, [canvasRefNullable, canvasState, leftHand, irisPosition]);

	useEffect(() => {
		const canvasRefOpt = newOption(canvasRefNullable.current);
		const canvasRef = canvasRefOpt.expect(
			"This is called after componentDidMount. CanvasRef is now not null.",
		);

		const ctxOpt = newOption(canvasRef.getContext("2d"));
		if (ctxOpt.kind === "none") {
			setError(newSome(t("eyesCanvas.contextError")));
		} else {
			const ctx = ctxOpt.data;
			draw(
				ctx,
				imageUtils,
				width,
				height,
				irisPosition,
				irisRadii,
				framePositions,
				imageObject,
				leftHandleObject,
				rightHandleObject,
				handleColor,
				leftHand,
				canvasState.selectedSideHandle,
				canvasState.selectedCornerHandle,
				alwaysUseLoupe,
				showSideHandles,
			);
		}
	});

	return error.kind === "none" ? (
		<canvas
			ref={canvasRefNullable}
			width={width}
			height={height}
			className="select-none"
		></canvas>
	) : (
		<p>{error.data}</p>
	);
};

const draw = (
	ctx: CanvasRenderingContext2D,
	imageUtils: ImageUtils,
	width: number,
	height: number,
	irisPosition: { left: Position; right: Position },
	irisRadii: IrisRadii,
	framesPositions: FramesPositionsSingle,
	imageObject: HTMLImageElement,
	leftHandleObject: HTMLImageElement,
	rightHandleObject: HTMLImageElement,
	handleColor: string,
	leftHand: boolean,
	selectedSideHandle: Option<SelectedSideHandle>,
	selectedCornerHandle: Option<SelectedCornerHandle>,
	alwaysUseLoupe: boolean,
	showSideHandles: boolean,
) => {
	ctx.clearRect(0, 0, width, height);

	if (imageUtils.viewBoxImage.y >= 0) {
		ctx.drawImage(
			imageObject,
			imageUtils.viewBoxImage.x,
			imageUtils.viewBoxImage.y,
			imageUtils.viewBoxImage.width,
			imageUtils.viewBoxImage.height,
			0,
			0,
			width,
			height,
		);
	} else {
		ctx.drawImage(
			imageObject,
			imageUtils.viewBoxImage.x,
			0,
			imageUtils.viewBoxImage.width,
			imageUtils.viewBoxImage.height,
			0,
			imageUtils.scaleFromImageToCanvas(-imageUtils.viewBoxImage.y),
			width,
			height,
		);
	}

	const canvas = {
		left: {
			bottomNasal: imageUtils.fromImageToCanvasPos(
				framesPositions.left.bottomNasal,
			),
			bottom: imageUtils.fromImageToCanvasPos(
				framesPositions.left.bottom,
			),
			bottomTemporal: imageUtils.fromImageToCanvasPos(
				framesPositions.left.bottomTemporal,
			),
			nasal: imageUtils.fromImageToCanvasPos(framesPositions.left.nasal),
			temporal: imageUtils.fromImageToCanvasPos(
				framesPositions.left.temporal,
			),
			topNasal: imageUtils.fromImageToCanvasPos(
				framesPositions.left.topNasal,
			),
			top: imageUtils.fromImageToCanvasPos(framesPositions.left.top),
			topTemporal: imageUtils.fromImageToCanvasPos(
				framesPositions.left.topTemporal,
			),
		},
		right: {
			bottomNasal: imageUtils.fromImageToCanvasPos(
				framesPositions.right.bottomNasal,
			),
			bottom: imageUtils.fromImageToCanvasPos(
				framesPositions.right.bottom,
			),
			bottomTemporal: imageUtils.fromImageToCanvasPos(
				framesPositions.right.bottomTemporal,
			),
			nasal: imageUtils.fromImageToCanvasPos(framesPositions.right.nasal),
			temporal: imageUtils.fromImageToCanvasPos(
				framesPositions.right.temporal,
			),
			topNasal: imageUtils.fromImageToCanvasPos(
				framesPositions.right.topNasal,
			),
			top: imageUtils.fromImageToCanvasPos(framesPositions.right.top),
			topTemporal: imageUtils.fromImageToCanvasPos(
				framesPositions.right.topTemporal,
			),
		},
	};

	const boxAngle = drawing.getVectorAngle({
		x: framesPositions.right.top.x - framesPositions.left.top.x,
		y: framesPositions.right.top.y - framesPositions.left.top.y,
	});

	drawing.drawRectangleFromSidePositions(
		ctx,
		canvas.left.bottom,
		canvas.left.temporal,
		canvas.left.nasal,
		canvas.left.top,
		boxAngle,
		1,
		handleColor,
	);

	drawing.drawRectangleFromSidePositions(
		ctx,
		canvas.right.bottom,
		canvas.right.nasal,
		canvas.right.temporal,
		canvas.right.top,
		boxAngle,
		1,
		handleColor,
	);

	[
		canvas.left.bottomNasal,
		canvas.left.bottomTemporal,
		canvas.left.topNasal,
		canvas.left.topTemporal,
		canvas.right.bottomNasal,
		canvas.right.bottomTemporal,
		canvas.right.topNasal,
		canvas.right.topTemporal,
	].forEach((pos) => {
		drawing.drawSmallCircle(ctx, pos.x, pos.y, 1, handleColor);
	});

	if (selectedCornerHandle.kind === "none") {
		[
			{
				ipos: framesPositions.left.bottom,
				ir: irisRadii.left,
				hn: "left-bottom",
				il: "hline" as LineProp,
			},
			{
				ipos: framesPositions.left.top,
				ir: irisRadii.left,
				hn: "left-top",
				il: "hline" as LineProp,
			},

			{
				ipos: framesPositions.right.bottom,
				ir: irisRadii.right,
				hn: "right-bottom",
				il: "hline" as LineProp,
			},
			{
				ipos: framesPositions.right.top,
				ir: irisRadii.right,
				hn: "right-top",
				il: "hline" as LineProp,
			},
			...(showSideHandles
				? [
						{
							ipos: framesPositions.right.nasal,
							ir: irisRadii.right,
							hn: "right-nasal",
							il: "vline" as LineProp,
						},
						{
							ipos: framesPositions.right.temporal,
							ir: irisRadii.right,
							hn: "right-temporal",
							il: "vline" as LineProp,
						},
						{
							ipos: framesPositions.left.nasal,
							ir: irisRadii.left,
							hn: "left-nasal",
							il: "vline" as LineProp,
						},
						{
							ipos: framesPositions.left.temporal,
							ir: irisRadii.left,
							hn: "left-temporal",
							il: "vline" as LineProp,
						},
					]
				: []),
		].forEach(({ ipos, ir, hn, il }) => {
			drawing.drawHandleWithoutEyeGoalWithOrWithoutLoupe(
				ctx,
				leftHandleObject,
				rightHandleObject,
				imageObject,
				imageUtils.fromImageToCanvasPos(ipos),
				ipos,
				imageUtils.scaleFromImageToCanvas(ir) * 0.75,
				boxAngle,
				handleColor,
				1,
				leftHand,
				selectedSideHandle
					.map(
						(h) =>
							hn === h.handle && (alwaysUseLoupe || h.doubleTap),
					)
					.unwrapOrDefault(false),

				il,
			);
		});
	} else {
		let ipos;
		let ir;
		switch (selectedCornerHandle.data.handle) {
			case "left-bottom-nasal":
				ipos = framesPositions.left.bottomNasal;
				ir = irisRadii.left;
				break;
			case "left-bottom-temporal":
				ipos = framesPositions.left.bottomTemporal;
				ir = irisRadii.left;
				break;
			case "left-top-nasal":
				ipos = framesPositions.left.topNasal;
				ir = irisRadii.left;
				break;
			case "left-top-temporal":
				ipos = framesPositions.left.topTemporal;
				ir = irisRadii.left;
				break;
			case "right-bottom-nasal":
				ipos = framesPositions.right.bottomNasal;
				ir = irisRadii.right;
				break;
			case "right-bottom-temporal":
				ipos = framesPositions.right.bottomTemporal;
				ir = irisRadii.right;
				break;
			case "right-top-nasal":
				ipos = framesPositions.right.topNasal;
				ir = irisRadii.right;
				break;
			case "right-top-temporal":
				ipos = framesPositions.right.topTemporal;
				ir = irisRadii.right;
				break;
		}
		drawing.drawHandleWithoutEyeGoalWithOrWithoutLoupe(
			ctx,
			leftHandleObject,
			rightHandleObject,
			imageObject,
			imageUtils.fromImageToCanvasPos(ipos),
			ipos,
			imageUtils.scaleFromImageToCanvas(ir) * 0.75,
			boxAngle,
			handleColor,
			1,
			leftHand,
			selectedCornerHandle.data.isTapped &&
				(alwaysUseLoupe || selectedCornerHandle.data.doubleTap),
			"nothing",
		);
	}
};

export default FramesCanvas;
